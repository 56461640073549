import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import React, { useMemo } from "react";
import {
  LockIcon,
  RemoveIcon,
  UnLockIcon,
  EditIcon,
  RestoreIcon,
  ViewIcon,
  StatusChangeIcon,
  SendIcon,
} from "../Icons/Icons";

const AGTabla = ({
  rowData,
  columnDefs,
  defaultColDef,
  changeStatus,
  handleDelete,
  handleEdit,
  handleRestore,
  sendNotification,
  openNotification,
  handleView,
  removeAction = false,
  handleOrderStatus,
}) => {
  const containerStyle = useMemo(
    () => ({ width: "100%", height: window.screen.height / 2 + 60 }),
    []
  );
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const sideBar = useMemo(() => {
    return {
      toolPanels: ["filters"],
    };
  }, []);
  const columnDefs1 = [
    {
      headerName: "Actions",
      field: "id",
      //cellRenderer: restaurantActions,
      cellRenderer: (params) => {
        return (
          <>
            {typeof handleOrderStatus === "function" && (
              <button
                className="btn  btn-sm mb-2"
                onClick={() => handleOrderStatus(params.data)}
              >
                <StatusChangeIcon />
              </button>
            )}
            {typeof changeStatus === "function" && (
              <button
                className={`btn ${
                  params.data.status === "P" ? "" : ""
                } btn-sm mb-2`}
                onClick={() => changeStatus(params)}
              >
                {params?.data?.status === "P" ? <LockIcon /> : <UnLockIcon />}
              </button>
            )}
            {typeof handleDelete === "function" && (
              <button
                className="btn  btn-sm mb-2"
                onClick={() => handleDelete(params.data.id)}
              >
                <RemoveIcon />
              </button>
            )}

            {typeof handleEdit === "function" && (
              <button
                className="btn  btn-sm mb-2"
                onClick={() => handleEdit(params.data)}
              >
                <EditIcon />
              </button>
            )}
            {typeof handleRestore === "function" && (
              <button
                className="btn  btn-sm mb-2"
                onClick={() => handleRestore(params.data?.id)}
              >
                <RestoreIcon />
              </button>
            )}
            {typeof handleView === "function" && (
              <button
                className="btn  btn-sm mb-2"
                onClick={() => handleView(params.data)}
              >
                {/* <RestoreIcon /> */}
                <ViewIcon />
              </button>
            )}
            {typeof openNotification === "function" && (
              <button
                className="btn  btn-sm mb-2"
                onClick={() => openNotification(params.data?.id)}
              >
                <SendIcon size={18} />
              </button>
            )}
          </>
        );
      },
    },
  ];

  const columnDefs2 = removeAction
    ? columnDefs
    : columnDefs1.concat(columnDefs);

  return (
    <div style={containerStyle}>
      <div style={gridStyle} className={"ag-theme-quartz"}>
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs2}
          defaultColDef={defaultColDef}
          sideBar={sideBar}
          pagination={true}
          paginationPageSize={20}
        />
      </div>
    </div>
  );
};
export default AGTabla;
