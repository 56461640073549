import * as Yup from "yup";

export const NotificationShema = Yup.object().shape({
	info: Yup.object().shape({
		notification_title: Yup.string().required(""),
		notification_description: Yup.string().required(""),
		notification_type: Yup.object().shape({
			value: Yup.string().required("Required"),
			label: Yup.string().required("Required"),
		}),
	}),
});
