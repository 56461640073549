import React, { useCallback, useEffect, useRef } from "react";
import AGTable from "../../../components/DesignSystem/AGTable";
import { columnDefs, defaultColDef } from "./Header";
import { OutlineButton } from "../../../components/DesignSystem/Buttons";

import { Typography } from "../../../components/DesignSystem/Typography";
import { useDispatch, useSelector } from "react-redux";
// import AddCuisine from "./AddCuisine";

import { selectNotificationInfo } from "../../../redux/notificationSlice";
import {
	deleteNotification,
	fetchNotificationDetails,
	updateNotificationStatus,
} from "../../../action";
import AddNotification from "./AddNotification";
import SendNotification from "./SendNotification";

const Notification = () => {
	//const [rowData, setRowData] = useState();
	const addNotificationModal = useRef();
	const sendNotificationModal = useRef();
	const dispatch = useDispatch();
	const selectNotificationsDet = useSelector(selectNotificationInfo);
	useEffect(() => {
		dispatch(fetchNotificationDetails());
	}, [dispatch]);

	const handleDelete = (id) => {
		dispatch(deleteNotification({ id: id }));
	};
	// const changeStatus = (item = "") => {
	//   dispatch(
	//     updateNotificationStatus({
	//       id: item?.data?.id,
	//       status: item?.data?.status,
	//     })
	//   );
	// };
	// const sendNotification = useCallback(() => {}, []);
	const addNotification = () => {
		addNotificationModal.current.handleAddNotification();
	};
	const openNotification = (item) => {
		sendNotificationModal.current.handleSendNotification(item);
	};
	const handleEdit = (item) => {
		addNotificationModal.current.handleAddNotification(item);
	};
	return (
		<>
			<div className="d-flex justify-content-between mb-2">
				<Typography align="text-start">Notification Listing</Typography>
				<OutlineButton label="Create Notification" onClick={addNotification} />
			</div>
			<AGTable
				handleEdit={handleEdit}
				handleDelete={handleDelete}
				//sendNotification={sendNotification}
				rowData={selectNotificationsDet}
				columnDefs={columnDefs}
				defaultColDef={defaultColDef}
				openNotification={openNotification}
			/>
			<AddNotification ref={addNotificationModal} />
			<SendNotification ref={sendNotificationModal} />
		</>
	);
};
export default Notification;
