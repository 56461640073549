import { createSlice } from "@reduxjs/toolkit";
import { getSelector } from "./helper";
import {
	fetchCategoryDetails,
	fetchLowOutOfStockProducts,
	fetchProductCategoryList,
	fetchProductDetails,
	fetchProductList,
	fetchProductVarientImages,
} from "../action";
import productDetailsNormalizer from "../Normalization/NormalizerSetUp";

const productSlice = createSlice({
	name: "productMng",
	initialState: {
		productDetails: [],
		productsList: [],
		varientImagesListList: [],
		lowStockProducts: [],
		outOfStockProducts: [],
		productCategoryList: [],
		loading: false,
		isSuccess: false,
		message: "",
	},
	reducers: {
		clear: (state) => {
			state.status = false;
			state.data = [];
			state.loading = false;
		},
		clearProductDetails: (state) => {
			state.status = false;
			state.productDetails = [];
			state.loading = false;
		},
	},
	extraReducers: {
		[fetchProductList.pending]: (state, action) => {
			state.loading = true;
		},
		[fetchProductList.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.productsList = payload?.items;
		},
		[fetchProductList.rejected]: (state, action) => {
			state.loading = false;
		},

		[fetchProductDetails.pending]: (state, action) => {
			state.loading = true;
		},
		[fetchProductDetails.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.productDetails = productDetailsNormalizer(payload?.items);
		},
		[fetchProductDetails.rejected]: (state, action) => {
			state.loading = false;
		},
		[fetchProductVarientImages.pending]: (state, action) => {
			state.loading = true;
		},
		[fetchProductVarientImages.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.varientImagesListList = payload?.items;
		},
		[fetchProductVarientImages.rejected]: (state, action) => {
			state.loading = false;
		},
		[fetchLowOutOfStockProducts.pending]: (state, action) => {
			state.loading = true;
		},
		[fetchLowOutOfStockProducts.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.lowStockProducts = payload?.lowStock;
			state.outOfStockProducts = payload?.outOfStock;
		},
		[fetchLowOutOfStockProducts.rejected]: (state, action) => {
			state.loading = false;
		},
		[fetchProductCategoryList.pending]: (state, action) => {
			state.loading = true;
		},
		[fetchProductCategoryList.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.productCategoryList = payload?.items;
		},
		[fetchProductCategoryList.rejected]: (state, action) => {
			state.loading = false;
		},
	},
});

export const { clear, clearProductDetails } = productSlice.actions;

export const selectProductInfo = getSelector("productSlice", "productsList");
export const selectProductDetails = getSelector(
	"productSlice",
	"productDetails"
);
export const selectProductVarientsImagesList = getSelector(
	"productSlice",
	"varientImagesListList"
);
export const selectLowStockProductsInfo = getSelector(
	"productSlice",
	"lowStockProducts"
);
export const selectOutOfStockProductsInfo = getSelector(
	"productSlice",
	"outOfStockProducts"
);
export const selectProductCategories = getSelector(
	"productSlice",
	"productCategoryList"
);

export default productSlice.reducer;
