export const BarChartSeries = [
	{
		type: "bar",
		xKey: "userType",
		yKey: "countUserType",
		yName: "countUserType",
	},
	// {
	// 	type: "bar",
	// 	xKey: "userType",
	// 	yKey: "countUserType",
	// 	yName: "countUserType",
	// },
];
export const PieChartSeries = [
	{
		type: "pie",
		angleKey: "countUserType",
		legendItemKey: "userType",
	},
];
