import React from "react";
import styled from "styled-components";

const Container = styled.span.attrs(() => ({
  className: "p-1",
}))`
  font-weight: bold;
  white-space: pre;
  text-transform: none;
  font-size: ${(props) => (props.size ? props.size : "21px")};
`;
export const Typography = ({ size, children }) => {
  return <Container size={size}>{children}</Container>;
};
