import React, { useMemo, useState } from "react";
const getDate = (value) => {
	var dateParts = value.split("/");
	return new Date(
		Number(dateParts[2]),
		Number(dateParts[1]) - 1,
		Number(dateParts[0])
	);
};
// {params.value}

const dateFilterParams = {
	filters: [
		{
			filter: "agDateColumnFilter",
			filterParams: {
				comparator: (filterDate, cellValue) => {
					if (cellValue == null) return -1;
					return getDate(cellValue).getTime() - filterDate.getTime();
				},
			},
		},
		{
			filter: "agSetColumnFilter",
			filterParams: {
				comparator: (a, b) => {
					return getDate(a).getTime() - getDate(b).getTime();
				},
			},
		},
	],
};
export const columnDefs = [
	{
		headerName: "Title",
		field: "notification_title",
		filter: "agMultiColumnFilter",
	},
	{
		headerName: "Type",
		field: "type",
		filter: "agMultiColumnFilter",
	},

	{
		headerName: "Updated Date",
		field: "updated_at",
		filter: "agMultiColumnFilter",
		filterParams: dateFilterParams,
	},
];
export const defaultColDef = {
	flex: 1,
	minWidth: 200,
	menuTabs: ["filterMenuTab"],
};
