import "ag-grid-community/styles/ag-theme-quartz.css";
import { EditIcon } from "../../../components/DesignSystem/Icons/Icons";

const DeatailsComponent = ({ data, editStock }) => {
	return (
		<div style={{ backgroundColor: "#f0ffffad" }}>
			<h5 style={{ margin: 0, paddingBottom: 1 }}>{data.name}</h5>
			<div className="px-3 py-1">
				{data?.varients?.some((el) => el !== null) &&
				data?.varients?.length > 0 ? (
					<>
						<div
							className="text-start m-2"
							style={{ fontSize: "18px", fontWeight: "500" }}
						>
							Varients
						</div>
						<table class="table mb-3">
							<tr style={{ backgroundColor: "#e5e5e5" }}>
								<th scope="col" className="p-2">
									#
								</th>
								<th scope="col">Unit</th>
								<th scope="col">Price</th>
								<th scope="col">Stock</th>
								<th scope="col">Selling Price</th>
								{/* <th scope="col">Quantity</th> */}
								<th scope="col">Action</th>
							</tr>
							<tbody>
								{data?.varients?.length > 0 &&
									data?.varients.map((item, i) => {
										if (item?.unit != null)
											return (
												<tr>
													<th scope="row" className="py-3">
														{i + 1}
													</th>
													<td className="py-3">{item?.unit}</td>
													<td className="py-3">{item?.price}</td>
													<td className="py-3">{item?.stock}</td>
													<td className="py-3">{item?.selling_price}</td>
													{/* <td className="py-3">{item?.quantity}</td> */}
													<td className="py-3">
														<span
															className="cp"
															onClick={() => editStock(item)}
														>
															<EditIcon />
														</span>
													</td>
												</tr>
											);
									})}
							</tbody>
						</table>
					</>
				) : (
					<div className="text-center ">
						<h3>No data available</h3>
					</div>
				)}
			</div>
		</div>
	);
};

export default DeatailsComponent;
