import * as Yup from "yup";

export const AddressChangeShema = Yup.object().shape({
	info: Yup.object().shape({
		delevery_street_address: Yup.string().required(""),
		delivery_suburb: Yup.string().required(""),
		delivery_state: Yup.string().required(""),
		delivery_postal_code: Yup.number().required(""),
	}),
});
