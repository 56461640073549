import React from "react";
import styled from "styled-components";

export const OutlineButtonStyle = styled.button`
  color: var(--primary);
  font-size: 15px;
  font-weight: 500;
  border: 1px solid;
  padding: 6px 10px;
  border-radius: 13px;
  pointer-events: ${(props) => (props.disabled ? "none" : null)};
  &:hover {
    background-color: var(--primary);
    color: #ffff;
    cursor: ${(props) => (props.disabled ? "not-allowed" : null)};
  }
`;
export const FullOutlineButtonStyle = styled.button`
  width: 100%;
  color: var(--primary);
  font-size: 15px;
  font-weight: 500;
  border: 1px solid;
  padding: 5px 15px;
  border-radius: 13px;
  pointer-events: ${(props) => (props.disabled ? "none" : null)};
  &:hover {
    background-color: var(--primary);
    color: #ffff;
    cursor: ${(props) => (props.disabled ? "not-allowed" : null)};
  }
`;
const FullWidthButtonStyle = styled.button`
	width: 100%;
      background-color: var(--primary);
    color: #ffff;

    font-size: 15px;
    font-weight: 500;
    padding: 12px 15px;
}
  pointer-events: ${(props) => (props.disabled ? "none" : null)};
  &:hover {
        color: rgb(1, 63, 108);
 background-color: #EFEFEF;
    cursor: ${(props) => (props.disabled ? "not-allowed" : null)};
  }
`;
export const OutlineButton = ({
  label,
  onClick,
  isDisabled = false,
  children,
}) => {
  return (
    <OutlineButtonStyle disabled={isDisabled} onClick={onClick}>
      <div>
        {label} {children}
      </div>
    </OutlineButtonStyle>
  );
};
export const FullOutlineButton = ({ label, onClick, isDisabled = false }) => {
  return (
    <FullOutlineButtonStyle disabled={isDisabled} onClick={onClick}>
      {label}
    </FullOutlineButtonStyle>
  );
};
export const FullWidthButton = ({ label, onClick, isDisabled = false }) => {
  return (
    <FullWidthButtonStyle disabled={isDisabled} onClick={onClick}>
      {label}
    </FullWidthButtonStyle>
  );
};
