import { forwardRef } from "react";
import { isObjectEmpty } from "../../../util";
import { TextBoxContainer } from "./styles";

const getErrorField = (errors, name) => {
  const fieldName = name.split(".");
  return !isObjectEmpty(errors)
    ? fieldName?.length === 2
      ? errors[fieldName[0]][fieldName[1]]
      : errors[name]
    : "";
};
export const InputField = forwardRef(
  (
    {
      name,
      value,
      errors = "",
      placeholder,
      isDisabled = false,
      isReadonly = false,
      errorMessage,
      type,
      styles,
      onClick,
      ...rest
    },
    ref
  ) => {
    return (
      <TextBoxContainer>
        <div className="custom-field one ">
          <input
            type={type}
            value={value}
            readOnly={isReadonly}
            disabled={isDisabled}
            label={placeholder}
            placeholder=""
            onClick={onClick}
            className={`form-control ${styles} ${
              getErrorField(errors, name) ? "error-msg" : ""
            }`}
            id={name}
            ref={ref}
            {...rest}
          />
          <span class="placeholders">{placeholder}</span>

          {errors && (
            <label className="error-msg">{errors[name]?.message}</label>
          )}
        </div>
      </TextBoxContainer>
    );
  }
);
