import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AgChartsReact } from "ag-charts-react";

const AGBarChart = ({
  getUserTypeDetails,
  barChartSeries,
  getOrderReportsDetails,
  title,
}) => {
  const [options, setOptions] = useState({});
  useEffect(() => {
    setOptions({
      height: 320,
      title: {
        text: title,
      },

      data: getOrderReportsDetails,
      series: [{ type: "bar", xKey: "date", yKey: "view" }],
    });
  }, [getUserTypeDetails, getOrderReportsDetails, barChartSeries]);

  return (
    <div className="shadow-sm border ">
      <AgChartsReact options={options} />
    </div>
  );
};

export default AGBarChart;
