import { modalIfo } from "../redux/tostSlice";

export const dispatchErrorToastAction = (errors, dispatch) => {
  dispatch(
    modalIfo({
      msg: errors?.message,
      isModal: false,
      isErorModalCLose: true,
      isModalCLose: false,
      isLoading: false,
    })
  );
};
