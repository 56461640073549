import React, {
	forwardRef,
	useImperativeHandle,
	useRef,
	useState,
} from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";

import {
	InputField,
	SelectField,
	TextAreaField,
} from "../../../components/DesignSystem/FormField";
import { OutlineButton } from "../../../components/DesignSystem/Buttons";
import ModalBox from "../../../components/DesignSystem/ModalBox";
import { addNotification } from "../../../action";
import { NotificationShema } from "../../../validationShema/NotificationShema";
import { filterObjectByValue } from "../../../util";
import { Image } from "../../../components/DesignSystem/Image";
const optionsForGroup = [
	{ value: "C", label: "Customer" },
	{ value: "R", label: "Deliver Rider" },
];
const resData = {
	info: {
		notification_title: "",
		notification_description: "",
		notification_type: "",
		image: "",
	},
};
const AddNotification = forwardRef(({}, ref) => {
	const [isEdit, setIsEdit] = useState(false);

	const dispatch = useDispatch();
	const openModal = useRef();
	const imageRef = useRef();
	const formOptions = {
		resolver: yupResolver(NotificationShema),
	};
	const {
		formState: { errors },
		handleSubmit,
		control,
		reset,
		setValue,
	} = useForm(formOptions, {
		defaultValues: {
			...resData,
		},
	});

	useImperativeHandle(ref, (item) => ({
		handleAddNotification(item) {
			if (item) {
				setIsEdit(true);
				setValue("info", item);
				setValue(
					"info.notification_type",
					filterObjectByValue(optionsForGroup, item?.notification_type)
				);
				imageRef.current.resetImage(item?.image);
			} else {
				reset(resData);
				setIsEdit(false);
				imageRef.current.resetImage("");
			}
			openModal.current.handleModalBox();
		},
	}));

	const onSubmit = (obj, e) => {
		obj.info.notification_type = obj.info.notification_type.value;
		obj.info.image = obj.info?.n_image;
		const data = obj.info;
		dispatch(addNotification(data));
		reset({ ...resData });
	};
	return (
		<ModalBox
			ref={openModal}
			size="lg"
			title={`${isEdit ? "Edit" : "Add"} Notification`}
		>
			<div className="container p-2">
				<div className="p-2">
					<form className="box" onSubmit={handleSubmit(onSubmit)}>
						<div className="row">
							<div className="col-md-12">
								<Controller
									name="info.notification_title"
									control={control}
									render={({ field: { onChange, name, value, ref } }) => (
										<InputField
											name={name}
											value={value}
											errors={errors}
											placeholder="Notification title"
											errorMessage="Notification title"
											type="text"
											onChange={onChange}
											ref={ref}
										/>
									)}
								/>
							</div>
							<div className="col-md-12">
								<Controller
									name="info.notification_description"
									control={control}
									render={({ field: { onChange, name, value, ref } }) => (
										<TextAreaField
											name={name}
											value={value}
											errors={errors}
											placeholder="Notification title"
											errorMessage="Notification title"
											type="text"
											onChange={onChange}
											ref={ref}
										/>
									)}
								/>
							</div>
							<div className="col-md-12 mt-3">
								<Controller
									name="info.notification_type"
									control={control}
									render={({ field: { onChange, value, name, ref } }) => (
										<SelectField
											name={name}
											value={value}
											optionsItems={optionsForGroup}
											errors={errors}
											isDisabled={isEdit}
											placeholder="Please select group"
											errorMessage="Please select group."
											ref={ref}
											onChange={onChange}
										/>
									)}
								/>
							</div>

							<div className="col-md-6">
								<Image
									errors={errors}
									control={control}
									ref={imageRef}
									setValue={setValue}
									image_name="info.n_image"
								/>
							</div>
						</div>

						<div className="d-flex justify-content-end  mt-2">
							<OutlineButton
								label={`${isEdit ? "Update" : "Add"}`}
								//onClick={addCategory}
							/>
						</div>
					</form>
				</div>
			</div>
		</ModalBox>
	);
});
export default AddNotification;
