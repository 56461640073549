import { createSlice } from "@reduxjs/toolkit";
import { getSelector } from "./helper";
import {
  fetchAllUserDetails,
  fetchUserDetails,
  fetchUserTypeDetails,
} from "../action/userAction";
import { userDetailsNormalizer } from "../Normalization/NormalizerSetUp";

const userSlice = createSlice({
  name: "userAccount",
  initialState: {
    userDetails: [],
    getUsersDetails: [],
    loading: false,
    isSuccess: false,
    message: "",
  },
  reducers: {
    clear: (state) => {
      state.status = false;
      state.data = [];
      state.loading = false;
    },
    updateUserDetails: (state, { payload }) => {
      state.userDetails = payload;
    },
    updateCartItemCount: (state, { payload }) => {
      state.cartItemCount = payload;
    },
  },
  extraReducers: {
    [fetchUserDetails.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchUserDetails.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.userDetails = payload?.data;
    },
    [fetchUserDetails.rejected]: (state, action) => {
      state.loading = false;
    },
    [fetchAllUserDetails.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchAllUserDetails.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.getUsersDetails = userDetailsNormalizer(payload?.data);
    },
    [fetchAllUserDetails.rejected]: (state, action) => {
      state.loading = false;
    },
    [fetchUserTypeDetails.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchUserTypeDetails.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.getUsersTypeDetails = payload?.data;
    },
    [fetchUserTypeDetails.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});
export const { clear, updateUserDetails, updateCartItemCount } =
  userSlice.actions;

export const selectUserAccountInfo = getSelector("userSlice", "userDetails");
export const selectUsersDetailsInfo = getSelector(
  "userSlice",
  "getUsersDetails"
);
export const selectUsersTypeDetailsInfo = getSelector(
  "userSlice",
  "getUsersTypeDetails"
);
export default userSlice.reducer;
