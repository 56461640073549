import { combineReducers } from "redux";
import userSlice from "./userSlice";
import tostSlice from "./tostSlice";
import categorySlice from "./categorySlice";
import bannerSlice from "./bannerSlice";
import notificationSlice from "./notificationSlice";
import productSlice from "./productSlice";
import trashSlice from "./trashSlice";
import inventorySlice from "./inventorySlice";
import orderSlice from "./orderSlice";
import genricStateSlice from "./genricStateSlice";
import paymentSlice from "./paymentSlice";

const rootReducer = combineReducers({
	userSlice,
	tostSlice,
	categorySlice,
	bannerSlice,
	notificationSlice,
	productSlice,
	trashSlice,
	inventorySlice,
	orderSlice,
	genricStateSlice,
	paymentSlice,
});

export default rootReducer;
