import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import {
  fetchCategoryDetails,
  fetchProductCategoryList,
} from "../../../action";

import { clearCategory } from "../../../redux/categorySlice";
import NavTabs from "../../../components/DesignSystem/NavTabs";
import { CategoryTabs } from "../../../config/nav-config";
import Category from "./Category";
import Subcategory from "./SubCategory";

const CategorySubcategory = () => {
  const [categoryType, setCategoryType] = useState("P");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearCategory());
    dispatch(fetchCategoryDetails());
    dispatch(fetchProductCategoryList());
  }, [dispatch]);

  const getActiveNavValue = (activeUser) => {
    setCategoryType(activeUser?.value);
  };
  return (
    <>
      <div style={{ backgroundColor: "#e9e9e9" }}>
        <NavTabs
          navItems={CategoryTabs}
          getActiveNavValue={getActiveNavValue}
        />
      </div>
      {categoryType === "P" && <Category />}
      {categoryType === "S" && <Subcategory />}
    </>
  );
};
export default CategorySubcategory;
