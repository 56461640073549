import * as Yup from "yup";

const regex =
	// eslint-disable-next-line no-useless-escape
	/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const validatePhone = (phone) => {
	return Yup.number()
		.integer()
		.positive()
		.test((phone) => {
			return phone && phone.toString().length === 10 ? true : false;
		})
		.isValidSync(phone);
};

export const LoginShema = Yup.object().shape({
	email: Yup.string()
		.required("")
		.test("", (value) => {
			return regex.test(value) || validatePhone(parseInt(value ?? "0"));
		}),
	password: Yup.string().required(""),
});
