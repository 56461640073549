import React, { useEffect } from "react";
import AGTable from "../../../components/DesignSystem/AGTable";
import { columnDefs, defaultColDef } from "./Header";
import { OutlineButton } from "../../../components/DesignSystem/Buttons";

import { Typography } from "../../../components/DesignSystem/Typography";
import { useDispatch, useSelector } from "react-redux";

import {
  clearProductDetails,
  selectProductInfo,
} from "../../../redux/productSlice";
import {
  deleteProduct,
  fetchProductList,
  updateProductStatus,
} from "../../../action";
import { useMyNavigation } from "../../../hooks";
import { clear } from "../../../redux/genricStateSlice";

const Product = () => {
  const dispatch = useDispatch();
  const { push } = useMyNavigation();

  const selectProductsDet = useSelector(selectProductInfo);

  useEffect(() => {
    dispatch(clear());
    dispatch(fetchProductList());
    dispatch(clearProductDetails());
  }, [dispatch]);

  const handleDelete = (id) => {
    dispatch(deleteProduct({ id: id }));
  };
  const changeStatus = (item = "") => {
    dispatch(
      updateProductStatus({ id: item?.data?.id, status: item?.data?.status })
    );
  };

  const handlForm = ({ id }) => {
    if (id) push("/manage-product", { id });
    else push("/create-product");
  };
  return (
    <>
      <div className="d-flex justify-content-between mb-2">
        <Typography align="text-start">Product Listing</Typography>
        <OutlineButton label="Add Product" onClick={handlForm} />
      </div>
      <AGTable
        handleEdit={handlForm}
        handleDelete={handleDelete}
        changeStatus={changeStatus}
        rowData={selectProductsDet}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
      />
    </>
  );
};
export default Product;
