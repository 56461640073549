import { Controller } from "react-hook-form";
import {
  InputField,
  SelectField,
  TextAreaField,
} from "../../../components/DesignSystem/FormField";
import NestedSelectFields from "../../../components/DesignSystem/FormField/NestedSelectFields";
import { useDispatch, useSelector } from "react-redux";
import { UNIT_TYPE } from "../../../Constant";
import { ProductVarient } from "./ProductVarient";
// import { Image } from "../../../components/DesignSystem/Image";
import { useEffect } from "react";
import { fetchProductCategoryList, getSubCategoryById } from "../../../action";
import { selectProductCategories } from "../../../redux/productSlice";
import { selectSubCategoryById } from "../../../redux/categorySlice";
export default function ProductForm({
  control,
  errors,
  setValue,
  isEdit = false,
  viewPhotoGallery,
  addPhotoGallery,
  watch,
  getValues,
  imageRef,
  unitType,
}) {
  const dispatch = useDispatch();
  const getCategorySubcategoryDet = useSelector(selectProductCategories);
  const getSubcategoryDetById = useSelector(selectSubCategoryById);
  useEffect(() => {
    dispatch(fetchProductCategoryList());
  }, [dispatch]);
  const handleSubcategory = (item) => {
    //console.log(item, "========");
    setValue("info.category", "");
    dispatch(getSubCategoryById({ id: item }));
  };
  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <Controller
            name="info.product_name"
            control={control}
            render={({ field: { onChange, name, value, ref } }) => (
              <InputField
                name={name}
                value={value}
                errors={errors}
                placeholder="Product name"
                errorMessage="Product name"
                type="text"
                onChange={onChange}
                ref={ref}
              />
            )}
          />
        </div>
        <div className="col-md-6 ">
          <Controller
            name="info.quantity_alert"
            control={control}
            render={({ field: { onChange, name, value, ref } }) => (
              <InputField
                name={name}
                value={value}
                errors={errors}
                placeholder="Quantity alert"
                errorMessage="Quantity alert"
                type="text"
                onChange={onChange}
                ref={ref}
              />
            )}
          />
        </div>
        <div className="col-md-6">
          <Controller
            name="info.product_category"
            control={control}
            render={({ field: { onChange, value, name, ref } }) => (
              <NestedSelectFields
                name={name}
                value={value}
                errors={errors}
                isSearchable={true}
                placeholder="Select Category"
                errorMessage="Please Category"
                ref={ref}
                //onChange={onChange}
                onChange={(e) => {
                  onChange(e);
                  handleSubcategory(e?.value);
                }}
                width="100%"
                options={getCategorySubcategoryDet}
              />
            )}
          />
        </div>

        <div className="col-md-6">
          <Controller
            name="info.category"
            control={control}
            render={({ field: { onChange, value, name, ref } }) => (
              <SelectField
                name={name}
                value={value}
                optionsItems={getSubcategoryDetById || []}
                errors={errors}
                placeholder="Select Sub Category"
                errorMessage="Please select Sub Category."
                ref={ref}
                onChange={onChange}
              />
            )}
          />
        </div>
        {/* <div className="col-md-6 mt-3">
					<Controller
						name="info.unit_type"
						control={control}
						render={({ field: { onChange, value, name, ref } }) => (
							<SelectField
								name={name}
								value={value}
								optionsItems={UNIT_TYPE}
								errors={errors}
								placeholder="Select a unit type"
								errorMessage="Please select unit type."
								ref={ref}
								onChange={onChange}
							/>
						)}
					/>
				</div> */}

        <div className="col-md-12 ">
          <ProductVarient
            control={control}
            errors={errors}
            watch={watch}
            setValue={setValue}
            isEdit={isEdit}
            viewPhotoGallery={viewPhotoGallery}
            getValues={getValues}
            addPhotoGallery={addPhotoGallery}
            UNIT_TYPE={unitType}
          />
        </div>
        {/* <div className="col-md-12">
          <Controller
            name="info.product_description"
            control={control}
            render={({ field: { onChange, name, value, ref } }) => (
              <TextAreaField
                name={name}
                value={value}
                errors={errors}
                placeholder="Description"
                errorMessage="Description"
                type="text"
                rules={{ required: true }}
                onChange={onChange}
                ref={ref}
              />
            )}
          />
        </div> */}
        {/* <div className="col-md-12 mt-3">
          <Image
            errors={errors}
            control={control}
            ref={imageRef}
            setValue={setValue}
            image_name="info.p_image"
          />
        </div> */}
      </div>
    </>
  );
}
