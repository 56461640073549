import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import { InputField } from "../../../../components/DesignSystem/FormField";
import { OutlineButton } from "../../../../components/DesignSystem/Buttons";
import ModalBox from "../../../../components/DesignSystem/ModalBox";
import { updateOrderDeliveryAddress } from "./../../../../action/orderAction";
import { AddressChangeShema } from "../../../../validationShema/AddressChangeShema";
import { yupResolver } from "@hookform/resolvers/yup";
const resData = {
	info: {
		delevery_street_address: "",
		delivery_suburb: "",
		delivery_state: "",
		delivery_postal_code: "",
		id: "",
		order_id: "",
	},
};
const OrderChangeAddress = forwardRef(({}, ref) => {
	const dispatch = useDispatch();
	const openModal = useRef();
	const formOptions = {
		resolver: yupResolver(AddressChangeShema),
	};
	const {
		formState: { errors },
		handleSubmit,
		control,
		reset,
		setValue,
	} = useForm(formOptions, {
		defaultValues: {
			...resData,
		},
	});
	useImperativeHandle(ref, () => ({
		handleOrderChangeAddress(item, id) {
			if (item) {
				setValue("info", item);
				setValue("info.order_id", id);
			} else {
				reset(resData);
			}
			openModal.current.handleModalBox();
		},
	}));

	const onSubmit = ({ info }) => {
		dispatch(
			updateOrderDeliveryAddress({
				...info,
			})
		);
		reset({ ...resData });
	};
	return (
		<ModalBox ref={openModal} size="md" title={`Edit Order Address`}>
			<div className="container p-2">
				<div className="p-2">
					<form className="box" onSubmit={handleSubmit(onSubmit)}>
						<div className="row">
							<div className="col-md-12 ">
								<Controller
									name="info.delevery_street_address"
									control={control}
									render={({ field: { onChange, value, name, ref } }) => (
										<InputField
											name={name}
											value={value}
											errors={errors}
											placeholder="Enter street addrees"
											errorMessage="Please enter addrees."
											ref={ref}
											onChange={onChange}
										/>
									)}
								/>
							</div>
							<div className="col-md-6">
								<Controller
									name="info.delevery_street_address"
									control={control}
									render={({ field: { onChange, value, name, ref } }) => (
										<InputField
											name={name}
											value={value}
											errors={errors}
											placeholder="Enter street addrees"
											errorMessage="Please enter addrees."
											ref={ref}
											onChange={onChange}
										/>
									)}
								/>
							</div>
							<div className="col-md-6">
								<Controller
									name="info.delivery_suburb"
									control={control}
									render={({ field: { onChange, value, name, ref } }) => (
										<InputField
											name={name}
											value={value}
											errors={errors}
											placeholder="Enter suburb"
											errorMessage="Please enter suburb."
											ref={ref}
											onChange={onChange}
										/>
									)}
								/>
							</div>
							<div className="col-md-6">
								<Controller
									name="info.delivery_state"
									control={control}
									render={({ field: { onChange, value, name, ref } }) => (
										<InputField
											name={name}
											value={value}
											errors={errors}
											placeholder="Enter state"
											errorMessage="Please enter state."
											ref={ref}
											onChange={onChange}
										/>
									)}
								/>
							</div>
							<div className="col-md-6">
								<Controller
									name="info.delivery_postal_code"
									control={control}
									render={({ field: { onChange, value, name, ref } }) => (
										<InputField
											name={name}
											value={value}
											errors={errors}
											placeholder="Enter postal code"
											errorMessage="Please enter postal code."
											ref={ref}
											onChange={onChange}
										/>
									)}
								/>
							</div>
						</div>
						<div className="d-flex justify-content-end mt-1">
							<OutlineButton label="Update Address" />
						</div>
					</form>
				</div>
			</div>
		</ModalBox>
	);
});
export default OrderChangeAddress;
