import { createSlice } from "@reduxjs/toolkit";
import { getSelector } from "./helper";
import { fetchCategoryDetails, getSubCategoryById } from "../action";
import { categoryNormalizers } from "../Normalization/NormalizerSetUp";

const categorySlice = createSlice({
	name: "categoryMng",
	initialState: {
		categoryList: [],
		subCategoryList: [],
		parentCategoryList: [],
		subCategoryById: [],
		unitType: [],
		loading: false,
		isSuccess: false,
		message: "",
	},
	reducers: {
		clearCategory: (state) => {
			state.status = false;
			state.loading = false;
		},
	},
	extraReducers: {
		[fetchCategoryDetails.pending]: (state, action) => {
			state.loading = true;
		},
		[fetchCategoryDetails.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.subCategoryList = categoryNormalizers(payload?.items, true);
			state.categoryList = categoryNormalizers(payload?.items);
			state.parentCategoryList = categoryNormalizers(payload?.items, "", true);
			state.unitType = payload?.unitType;
		},
		[fetchCategoryDetails.rejected]: (state, action) => {
			state.loading = false;
		},
		[getSubCategoryById.pending]: (state, action) => {
			state.loading = true;
		},
		[getSubCategoryById.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.subCategoryById = payload?.items;
		},
		[getSubCategoryById.rejected]: (state, action) => {
			state.loading = false;
		},
	},
});

export const { clearCategory } = categorySlice.actions;

export const selectCategory = getSelector("categorySlice", "categoryList");
export const selectParentCategory = getSelector(
	"categorySlice",
	"parentCategoryList"
);
export const selectSubCategory = getSelector(
	"categorySlice",
	"subCategoryList"
);
export const selectSubCategoryById = getSelector(
	"categorySlice",
	"subCategoryById"
);
export const selectUnitType = getSelector("categorySlice", "unitType");

export default categorySlice.reducer;
