import * as Yup from "yup";

export const PaymentShema = Yup.object().shape({
	info: Yup.object().shape({
		bank_name: Yup.string().required(""),
		account_name: Yup.string().required(""),
		account_no: Yup.string().required(""),
		bsb: Yup.string().required(""),
		// is_enable_disable_bank_payment: Yup.string().required(""),
		// is_enable_disable_cash_payment: Yup.string().required(""),
	}),
});
