import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../service";
import TostAction from "./toastAction";
import { requestApi } from "../redux/tostSlice";
import { dispatchErrorToastAction } from "./commonFunction";

export const addBanner = createAsyncThunk(
	"bannerSlice/addBanner",
	async (object, { dispatch, rejectWithValue }) => {
		try {
			dispatch(requestApi());
			const param = {
				method: "post",
				endpoint: "banner",
				object: object,
			};

			const retunThunk = request(param);
			retunThunk
				.then((res) => {
					TostAction(dispatch, res, 1, 0, 0, 1);
					dispatch(fetchBannerDetails());
				})
				.catch((error) => dispatchErrorToastAction(error, dispatch));

			return retunThunk;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const fetchBannerDetails = createAsyncThunk(
	"bannerSlice/fetchBannerDetails",
	async (queryparam, { dispatch, rejectWithValue }) => {
		try {
			dispatch(requestApi());
			const param = {
				method: "get",
				endpoint: "banner",
				id: queryparam,
			};

			const retunThunk = request(param);
			retunThunk
				.then((res) => {
					TostAction(dispatch, res, 0, 0, 0);
				})
				.catch((error) => dispatchErrorToastAction(error, dispatch));
			return retunThunk;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const deleteBanner = createAsyncThunk(
	"bannerSlice/deleteBanner",
	async (object, { dispatch, rejectWithValue }) => {
		try {
			dispatch(requestApi());
			const param = {
				method: "post",
				endpoint: "deleteBanner",
				object: object,
			};

			const retunThunk = request(param);
			retunThunk
				.then((res) => {
					TostAction(dispatch, res, 1, 0, 0, 1);
					dispatch(fetchBannerDetails());
				})
				.catch((error) => dispatchErrorToastAction(error, dispatch));
			return retunThunk;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);
export const updateBannerStatus = createAsyncThunk(
	"bannerSlice/updateBannerStatus",
	async (object, { dispatch, rejectWithValue }) => {
		try {
			dispatch(requestApi());
			const param = {
				method: "post",
				endpoint: "updateBannerStatus",
				object: object,
			};

			const retunThunk = request(param);
			retunThunk
				.then((res) => {
					TostAction(dispatch, res, 1, 0, 0, 1);
					dispatch(fetchBannerDetails());
				})
				.catch((error) => dispatchErrorToastAction(error, dispatch));
			return retunThunk;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const stripePayment = createAsyncThunk(
	"bannerSlice/stripePayment",
	async (object, { dispatch, rejectWithValue }) => {
		try {
			//dispatch(requestApi());
			const param = {
				method: "post",
				endpoint: "stripePayment",
				object: object,
			};

			const retunThunk = request(param);
			retunThunk
				.then((res) => {
					console.log(res.respons?.url, "======");
					window.location.href = res.respons?.url;
					//TostAction(dispatch, res, 1, 0, 0, 1);
					// dispatch(fetchBannerDetails());
				})
				.catch((error) => dispatchErrorToastAction(error, dispatch));

			return retunThunk;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);
