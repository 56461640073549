import React, { useMemo, useState } from "react";
const getDate = (value) => {
	var dateParts = value.split("/");
	return new Date(
		Number(dateParts[2]),
		Number(dateParts[1]) - 1,
		Number(dateParts[0])
	);
};
// {params.value}

export const columnDefs = [
	{
		headerName: "Order No",
		field: "orderNo",
		filter: "agMultiColumnFilter",
	},
	{
		headerName: "Product Name",
		field: "productName",
		filter: "agMultiColumnFilter",
	},
	// {
	//   field: "Image",
	//   cellRenderer: (params) => {
	//     return (
	//       <img
	//         style={{ width: "40px", height: "40px", borderRadius: "50%" }}
	//         src={`${process.env.REACT_APP_BASE_URL}/public${params.data.productImage}`}
	//       />
	//     );
	//   },
	// },
	{
		headerName: "Quantity",
		field: "puchesed_quantity",
		filter: "agMultiColumnFilter",
	},
	{
		headerName: "Selling Price",
		field: "varientSellingPrice",
		filter: "agMultiColumnFilter",
	},
	{
		headerName: "Total Price",
		field: "total_price",
		filter: "agMultiColumnFilter",
	},
	{
		headerName: "Created Date",
		field: "createdDate",
		filter: "agMultiColumnFilter",
	},
];
export const defaultColDef = {
	flex: 1,
	minWidth: 100,
	menuTabs: ["filterMenuTab"],
};
