import React, { forwardRef } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import styled from "styled-components";
import getErrorField, { isObjectEmpty } from "../../../util";

const animatedComponents = makeAnimated();
export const colourOptions = [
  { value: "ocean", label: "Ocean", color: "#00B8D9", isFixed: true },
  { value: "blue", label: "Blue", color: "#0052CC", isDisabled: true },
];

const Container = styled.div`
  .css-48brv-control {
    border: 1px solid #ddd;
    border-radius: 10px;
    font-size: 14px;
    height: 2.8rem;
    box-shadow: #ddd;
  }
  .css-xddej-control:hover {
    border: 1px solid #ddd;
  }
  .css-t3ipsp-control {
    border: 1px solid #ddd;
    box-shadow: #ddd;
  }
  margin-bottom: 6px;
`;

export const SelectField = forwardRef(
  (
    {
      name,
      optionsItems,
      errors,
      values,
      isMulti = false,
      isDisabled = false,
      placeholder,
      errorMessage,
      className,
      type,
      ...rest
    },
    ref
  ) => {
    return (
      <Container>
        <Select
          components={animatedComponents}
          //Select={opt}
          value={values}
          //defaultValue={optionsItems[0]}
          options={optionsItems}
          isMulti={isMulti}
          placeholder={placeholder}
          isDisabled={isDisabled}
          className={className}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              // className: className,
              borderColor:
                errors && getErrorField(errors, name) ? "#008000" : "",
              boxShadow: "none",
            }),
          }}
          id={name}
          ref={ref}
          {...rest}
        />
      </Container>
    );
  }
);
