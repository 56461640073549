import React, { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";

import { OutlineButton } from "../../../components/DesignSystem/Buttons";
import {
  addProduct,
  fetchCategoryDetails,
  fetchProductDetails,
} from "../../../action";

import { ProductShema } from "../../../validationShema/ProductShema";

import { Typography } from "../../../components/DesignSystem/Typography";
import { useMyNavigation } from "../../../hooks";
import { useLocation } from "react-router-dom";
import {
  selectProductCategories,
  selectProductDetails,
} from "../../../redux/productSlice";
import ProductForm from "./ProductForm";
import { selectTostInfo } from "../../../redux/tostSlice";
import { fetchGenricStateDetails } from "../../../redux/genricStateSlice";
import AddVariantImage from "./AddVariantImage";
import { selectUnitType } from "../../../redux/categorySlice";
import { EditVariantImage } from "./EditVariantImage";

const resData = {
  info: {
    isEdit: "",
    product_name: "",
    //p_image: "",
    quantity_alert: "",
    product_category: "",
    category: "",
    // product_description: "",
    unit_type: "",
    varientArray: [],
  },
};
const EditProduct = () => {
  const { state } = useLocation();
  const productDetails = useSelector(selectProductDetails);
  const getTostInfo = useSelector(selectTostInfo);
  const getImages = useSelector(fetchGenricStateDetails);
  const getCategorySubcategoryDet = useSelector(selectProductCategories);
  const unitType = useSelector(selectUnitType);
  //console.log({ getCategorySubcategoryDet });

  const viewImageRef = useRef();
  const imageRef = useRef();
  const addImageRef = useRef();

  const { push } = useMyNavigation();

  const dispatch = useDispatch();
  const formOptions = {
    resolver: yupResolver(ProductShema),
  };
  const {
    formState: { errors },
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
  } = useForm(formOptions, {
    defaultValues: {
      ...resData,
    },
  });

  useEffect(() => {
    if (state?.id) {
      dispatch(fetchProductDetails(state?.id));
      dispatch(fetchCategoryDetails());
    }
  }, [dispatch, reset, state?.id]);

  useEffect(() => {
    if (productDetails) {
      console.log({ productDetails });
      setValue("info", productDetails);
      setValue("info.isEdit", true);
      //imageRef.current.resetImage(productDetails?.image);
      setValue("info.product_category", productDetails?.product_category);
      setValue("info.category", productDetails?.category);
      //setValue("info.unit_type", productDetails?.unit_type);
      setValue("info.varientArray", productDetails?.edit_product_varients);
    }
  }, [productDetails, setValue]);

  const viewPhotoGallery = (id, product_id) => {
    viewImageRef.current.handleGallery({ id, product_id });
  };
  useEffect(() => {
    getTostInfo?.isModal && push("/products");
  }, [getTostInfo, push]);
  const onSubmit = ({ info }, e) => {
    console.log({ info });
    //return;

    dispatch(
      addProduct({
        ...info,
        // image: info.p_image,
        category: info?.category?.value,
        parent_sub_category: info?.product_category?.value,
        //unit_type: JSON.stringify(info?.unit_type),
        varientImages: getImages,
      })
    );
  };
  const uploadPhotoHandler = (index) => {
    addImageRef.current.handleGallery(index);
  };
  return (
    <>
      <div className="d-flex justify-content-between mb-2">
        <Typography align="text-start">Edit Product</Typography>
        <OutlineButton label="Back" onClick={() => push("/products")} />
      </div>
      <div className="container p-2">
        <div className="p-2">
          <form onSubmit={handleSubmit(onSubmit)}>
            <ProductForm
              control={control}
              errors={errors}
              setValue={setValue}
              isEdit={true}
              viewPhotoGallery={viewPhotoGallery}
              addPhotoGallery={uploadPhotoHandler}
              getValues={getValues}
              imageRef={imageRef}
              unitType={unitType}
            />
            <div className="d-flex justify-content-end mt-3">
              <OutlineButton label="Update Product" />
            </div>
          </form>
        </div>
      </div>
      <EditVariantImage ref={viewImageRef} />
      <AddVariantImage ref={addImageRef} />
    </>
  );
};
export default EditProduct;
