import React, { useEffect, useState } from "react";
import "./styles.css";
import { getKeyByValue } from "../../../util";

export const ToggleSwitch = ({
	checkBoxValues,
	getSelectedValue,
	value,
	name,
	isLabel = false,
}) => {
	const [activeState, setActiveState] = useState(value);
	const handleToggle = (e) => {
		const selectedValue =
			checkBoxValues[getKeyByValue(checkBoxValues, e.target?.value)];
		getSelectedValue(selectedValue);
		setActiveState(selectedValue);
	};
	useEffect(() => setActiveState(value), [value]);
	return (
		<>
			<label className="switch">
				<input
					name={name}
					type="checkbox"
					value={activeState}
					checked={Boolean(activeState === checkBoxValues?.left)}
					id="togBtn"
					onChange={handleToggle}
				/>
				<div
					className="slider round"
					style={{
						backgroundColor: Boolean(activeState === checkBoxValues?.left)
							? "#5BC142"
							: "#999999",
					}}
				>
					<div className="on">{isLabel && checkBoxValues?.left}</div>
					<span className="off">{isLabel && checkBoxValues?.right}</span>
				</div>
			</label>
		</>
	);
};
