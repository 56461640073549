import React, { Suspense, useState } from "react";

import "./style.css";
import { Header } from "./Header";
import { NavBar } from "./NavBar";
import { Routers } from "../Routers";

export const Layout = () => {
  const [open, setOpen] = useState(false);
  return (
    <div className="container-fluid position-relative d-flex ">
      {/* Sidebar Start*/}
      <div className={`sidebar  pb-3 ${open ? "open" : ""}`}>
        <NavBar setOpen={setOpen} open={open} />
      </div>
      {/* Sidebar End */}
      {/* Content Start */}
      <div
        className={`content ${open ? "open" : ""}`}
        style={{ width: "100%" }}
      >
        <Header setOpen={setOpen} open={open} />
        <div className="container-fluid item-center">
          <div>
            <div className="row g-4">
              <div className="col-sm-12 col-xl-12">
                <div className="body-color rounded h-100 p-2">
                  {/* <h6 className="mb-4">Basic Buttons</h6>
                <div className="m-n2"></div> */}
                  <Routers />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Content End */}
      {/* Back to Top */}
    </div>
  );
};
