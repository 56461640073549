import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import styled from "styled-components";
import { forwardRef } from "react";
import { useImperativeHandle } from "react";
import { InputField } from "../../../components/DesignSystem/FormField";
import { UploadIcon } from "../../../components/DesignSystem/Icons/Icons";
// import { BsCamera } from "react-icons/bs";
const defaultImage = require(`../../../images/drop.jpg`);

export const ImageUploadFile = forwardRef(({ uploadSingleFile }, ref) => {
  const [previmage, setImage] = useState(defaultImage);
  const [imageValidationMsg, setImageValidationMsg] = useState("");
  useImperativeHandle(ref, () => ({
    resetImage(image) {
      setImageValidationMsg("");
    },
  }));
  // useEffect(() => {
  ///public
  // 	setImage(image && process.env.REACT_APP_BASE_URL + image);
  // }, [image]);
  //previmage || defaultImage
  const handleChange = (event) => {
    const image = event.target.files[0];
    const maximumSize = 1 * 1024 * 1024;
    //console.log(maximumSize);
    setImageValidationMsg("");
    let returnFlag = true;
    if (!image) {
      setImageValidationMsg("Image is required");
      returnFlag = false;
    }
    if (image && !image.name.match(/\.(jpg|jpeg|png|PNG|JPG|JPEG)$/)) {
      setImageValidationMsg("Please select a valid image.");
      //setImage(defaultImage);
      returnFlag = false;
    }
    if (returnFlag) uploadSingleFile(event);
    return returnFlag;
  };
  const onInputClick = (event) => {
    event.target.value = "";
  };
  return (
    <ImageContainer>
      {" "}
      <ImageStyle>
        <span className="overlay">
          <UploadIcon size={50} />
        </span>
        <input
          type="file"
          className="form-control input-file"
          onClick={onInputClick}
          onChange={handleChange}
        />
        <div className=" div-containt">
          <img
            src={previmage || defaultImage}
            alt="..."
            className="img-sec border shadow-sm"
          ></img>{" "}
        </div>
      </ImageStyle>
      {false && (
        <span className="my-3 upload-title">Drag and Drop / Upload</span>
      )}
      <div className="text-danger">{imageValidationMsg}</div>
    </ImageContainer>
  );
});
const ImageStyle = styled.div`
  .img-sec {
    width: 165px;
    max-height: 132px;
    min-height: 130px;
    border-radius: 5px;
    object-fit: cover;
  }

  .input-file {
    opacity: 0;
    position: absolute;
    z-index: 1;
    width: 165px;
    max-height: 132px;
    min-height: 130px;
    top: 25px;
  }
`;
const ImageContainer = styled.div`
  position: relative;
  .overlay {
    opacity: 0;
    cursor: pointer;
    position: absolute;
    z-index: 1;
    top: 100px;
    left: 4rem;
  }
  .div-containt :hover {
    .overlay {
      opacity: 1;
    }
  }
  .upload-title {
    font-size: 15px;
  }
`;
