import { createSlice } from "@reduxjs/toolkit";
import { getSelector } from "./helper";
import {
	fetchOrderDetails,
	fetchOrderDetailsById,
	fetchOrderReports,
} from "../action";
import { orderNormlize } from "../Normalization/NormalizerSetUp";

const orderSlice = createSlice({
	name: "orderMng",
	initialState: {
		orderDetails: [],
		orderReportsdetails: [],
		orderdetailsById: [],
		loading: false,
		isSuccess: false,
		message: "",
	},
	reducers: {
		clear: (state) => {
			state.status = false;
			state.data = [];
			state.loading = false;
		},
	},
	extraReducers: {
		[fetchOrderDetails.pending]: (state, action) => {
			state.loading = true;
		},
		[fetchOrderDetails.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.orderDetails = orderNormlize(payload?.items);
		},
		[fetchOrderDetails.rejected]: (state, action) => {
			state.loading = false;
		},
		[fetchOrderReports.pending]: (state, action) => {
			state.loading = true;
		},
		[fetchOrderReports.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.orderReportsdetails = payload?.items;
		},
		[fetchOrderReports.rejected]: (state, action) => {
			state.loading = false;
		},
		[fetchOrderDetailsById.pending]: (state, action) => {
			state.loading = true;
		},
		[fetchOrderDetailsById.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.orderdetailsById = payload?.items;
		},
		[fetchOrderDetailsById.rejected]: (state, action) => {
			state.loading = false;
		},
	},
});

export const { clear } = orderSlice.actions;

export const selectOrderDetailsInfo = getSelector("orderSlice", "orderDetails");
export const selectOrderReportsInfo = getSelector(
	"orderSlice",
	"orderReportsdetails"
);
export const getOrderDetailsById = getSelector(
	"orderSlice",
	"orderdetailsById"
);

export default orderSlice.reducer;
