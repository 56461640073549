import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import React, { useMemo } from "react";

const AGNestedTable = ({
  rowData,
  columnDefs,
  defaultColDef,
  detailCellRenderer,
  removeAction = false,
}) => {
  const containerStyle = useMemo(
    () => ({ width: "100%", height: window.screen.height }),
    []
  );
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const sideBar = useMemo(() => {
    return {
      toolPanels: ["filters"],
    };
  }, []);

  return (
    <div style={containerStyle}>
      <div style={gridStyle} className={"ag-theme-quartz"}>
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          masterDetail={true}
          detailCellRenderer={detailCellRenderer}
          detailRowHeight={300}
          // onFirstDataRendered={onFirstDataRendered}
        />
      </div>
    </div>
  );
};
export default AGNestedTable;
