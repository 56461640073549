import React, { useCallback, useEffect, useState } from "react";

import NavTabs from "../../../components/DesignSystem/NavTabs";
import { ArchiveTabs } from "../../../config/nav-config";
import styled from "styled-components";
import Product from "./product";
import Category from "./category";
import { useDispatch } from "react-redux";
import {
  fetchTrashCategoryDetails,
  fetchTrashProductDetails,
} from "../../../action";
import SubCategory from "./subCategory";

const Container = styled.div.attrs(() => ({
  className: "",
}))`
  .ag-root-wrapper {
    border-radius: unset;
  }
`;
const Archives = () => {
  const [archiveType, setArchiveType] = useState("P");
  const dispatch = useDispatch();

  const getActiveNavValue = useCallback((activeUser) => {
    setArchiveType(activeUser?.value);
  }, []);

  useEffect(() => {
    dispatch(fetchTrashCategoryDetails());
    dispatch(fetchTrashProductDetails());
  }, [dispatch]);
  return (
    <Container>
      {/* <div className="d-flex justify-content-between mb-2">
				<Typography align="text-start">Archives Listing</Typography>
			</div> */}
      <div style={{ backgroundColor: "#e9e9e9" }}>
        <NavTabs navItems={ArchiveTabs} getActiveNavValue={getActiveNavValue} />
      </div>
      {archiveType === "P" && <Product />}
      {archiveType === "C" && <Category />}
      {archiveType === "S" && <SubCategory />}
      {/* {archiveType==='O' && <Product />} */}
    </Container>
  );
};
export default Archives;
