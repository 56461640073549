import React, { useEffect, useState } from "react";
import { AgChartsReact } from "ag-charts-react";

const AGPieChart = ({ getUserTypeDetails, pieChartSeries }) => {
  const [options, setOptions] = useState({});
  useEffect(() => {
    setOptions({
      height: 320,
      title: {
        text: "Users count",
      },

      data: getUserTypeDetails,
      series: pieChartSeries,
    });
  }, [getUserTypeDetails, pieChartSeries]);

  return (
    <div className="shadow-sm border ">
      <AgChartsReact options={options} />
    </div>
  );
};

export default AGPieChart;
