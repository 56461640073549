import React, { useRef } from "react";

import { columns, defColumnDefs } from "./Header";
import { useSelector } from "react-redux";
import { selectInventoryOutOfStockInfo } from "../../../../redux/inventorySlice";
import AddStock from "../AddStock";
import DeatailsComponent from "../DeatailsComponent";
import AGNestedTable from "../../../../components/DesignSystem/AGNestedTable";

const OutOfStock = () => {
	const getOutOfStockDetails = useSelector(selectInventoryOutOfStockInfo);
	const addStockModal = useRef();

	const editStock = (item) => {
		addStockModal.current.handleAddStock(item);
	};
	return (
		<>
			<AGNestedTable
				rowData={getOutOfStockDetails}
				columnDefs={columns}
				defaultColDef={defColumnDefs}
				detailCellRenderer={(props) => (
					<DeatailsComponent {...props} editStock={editStock} />
				)}
			/>
			<AddStock ref={addStockModal} />
		</>
	);
};
export default OutOfStock;
