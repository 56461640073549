export const EMAIL_MOBILE_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const UNIT_TYPE = [
  { label: "KG", value: "KG" },
  { label: "GRM", value: "GRM" },
  { label: "LTR", value: "LTR" },
  { label: "PICES", value: "PICES" },
  { label: "BOX", value: "BOX" },
  { label: "BUNCH", value: "BUNCH" },
];

export const CURRENCY = "$";
