import { success, error } from "../redux/tostSlice";
const TostAction = (
  dispatch,
  response,
  isModal,
  isModalCLose,
  isLoading,
  isNotification
) => {
  if (response.success) {
    dispatch(
      success({
        msg: response?.response,
        isModal,
        isModalCLose,
        isLoading,
        isNotification,
      })
    );
  } else if (response.error) {
    dispatch(error({ ...response, isNotification }));
  }
};

export default TostAction;
