import { forwardRef } from "react";
import "./styles.css";
import { TextAreaContainer } from "./styles";

export const TextAreaField = forwardRef(
	(
		{
			name,
			value,
			errors,
			isDisabled = false,
			placeholder,
			errorMessage,
			type,
			rows = "3",
			...rest
		},
		ref
	) => {
		return (
			<TextAreaContainer>
				{" "}
				<div className="custom-field one">
					<textarea
						type={type}
						placeholder=""
						disabled={isDisabled}
						className={`form-control ${errors[name] ? "field-border" : ""}`}
						id={name}
						value={value}
						rows={rows}
						ref={ref}
						{...rest}
					>
						{value}
					</textarea>
					<span class="placeholders">{placeholder}</span>
				</div>
			</TextAreaContainer>
		);
	}
);
