import React, {
	forwardRef,
	useImperativeHandle,
	useRef,
	useState,
} from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";

import {
	InputField,
	SelectField,
	TextAreaField,
} from "../../../components/DesignSystem/FormField";
import { OutlineButton } from "../../../components/DesignSystem/Buttons";
import ModalBox from "../../../components/DesignSystem/ModalBox";
import { addUser } from "../../../action";
import NavTabs from "../../../components/DesignSystem/NavTabs";
import { adminNavItems, managerNavItems } from "../../../config/nav-config";
import { UserShema } from "../../../validationShema/UserShema";
import useIsAuthenticate from "../../../hooks/useIsAuthenticate";
import { filterObjectByValue } from "../../../util";
const optionsForGender = [
	{ value: "Male", label: "Male" },
	{ value: "Female", label: "Female" },
	{ value: "Other", label: "Other" },
];
const resData = {
	info: {
		first_name: "",
		last_name: "",
		gender: "",
		email: "",
		mobile_no: "",
		password: "",
		repassword: "",
		user_type: "C",
		address: "",
		abn_number: "",
		tfn_number: "",
	},
};
const AddUser = forwardRef(({}, ref) => {
	const { userType } = useIsAuthenticate();

	const [isEdit, setIsEdit] = useState(false);
	const [usrType, setUsrType] = useState("");
	const [currentTabItems, setCurrentTabItems] = useState([]);

	const dispatch = useDispatch();
	const openModal = useRef();
	const formOptions = {
		resolver: yupResolver(UserShema),
	};
	const {
		formState: { errors },
		handleSubmit,
		control,
		reset,
		setValue,
	} = useForm(formOptions, {
		defaultValues: {
			...resData,
		},
	});
	useImperativeHandle(ref, (item) => ({
		handleAddUser(item, uType) {
			setValue("info.user_type", "C");

			if (item) {
				setIsEdit(true);
				setCurrentTabItems([
					filterObjectByValue(adminNavItems, item.user_type),
				]);
				setUsrType(item.user_type);
				setValue("info", item);
				setValue("info.gender", JSON.parse(item?.gender));
			} else {
				reset(resData);
				//setCurrentTabItems(userType === "A" ? adminNavItems : managerNavItems);
				setCurrentTabItems([
					userType === "A"
						? filterObjectByValue(adminNavItems, uType)
						: filterObjectByValue(managerNavItems, uType),
				]);
				setIsEdit(false);
				setUsrType(uType);
				setValue("info.user_type", uType);
			}
			openModal.current.handleModalBox();
		},
	}));

	const getActiveNavValue = (activeUser) => {
		setValue("info.user_type", activeUser?.value);
		setUsrType(activeUser?.value);
	};

	const onSubmit = (obj, e) => {
		obj.info.gender = JSON.stringify(obj?.info?.gender);
		const data = obj.info;
		dispatch(addUser(data));
		reset({ ...resData });
	};
	return (
		<ModalBox
			ref={openModal}
			size="lg"
			title={`${isEdit ? "Edit" : "Add"} User`}
		>
			<div className="container p-1">
				<div className="p-2">
					<form className="box" onSubmit={handleSubmit(onSubmit)}>
						<div style={{ backgroundColor: "#e9e9e9" }}>
							<NavTabs
								navItems={currentTabItems}
								getActiveNavValue={getActiveNavValue}
							/>
						</div>
						<div className="border border-top-0 ">
							<div className="row px-3 py-4">
								<div className="col-md-6">
									<Controller
										name="info.first_name"
										control={control}
										render={({ field: { onChange, name, value, ref } }) => (
											<InputField
												name={name}
												value={value}
												errors={errors}
												placeholder="First name"
												errorMessage="First name"
												type="text"
												onChange={onChange}
												ref={ref}
											/>
										)}
									/>
								</div>
								<div className="col-md-6">
									<Controller
										name="info.last_name"
										control={control}
										render={({ field: { onChange, name, value, ref } }) => (
											<InputField
												name={name}
												value={value}
												errors={errors}
												placeholder="Last name"
												errorMessage="Last name"
												type="text"
												onChange={onChange}
												ref={ref}
											/>
										)}
									/>
								</div>
								<div className="col-md-6">
									<Controller
										name="info.email"
										control={control}
										render={({ field: { onChange, name, value, ref } }) => (
											<InputField
												name={name}
												value={value}
												errors={errors}
												placeholder="Email id"
												errorMessage="Enter email id"
												type="text"
												onChange={onChange}
												ref={ref}
											/>
										)}
									/>
								</div>
								<div className="col-md-6">
									<Controller
										name="info.mobile_no"
										control={control}
										render={({ field: { onChange, name, value, ref } }) => (
											<InputField
												name={name}
												value={value}
												errors={errors}
												placeholder="Mobile no"
												errorMessage="Enter mobile no"
												type="text"
												onChange={onChange}
												ref={ref}
											/>
										)}
									/>
								</div>
								{!isEdit && (
									<>
										{" "}
										<div className="col-md-6">
											<Controller
												name="info.password"
												control={control}
												render={({ field: { onChange, name, value, ref } }) => (
													<InputField
														name={name}
														value={value}
														errors={errors}
														placeholder="Password"
														errorMessage="Enter password"
														type="password"
														onChange={onChange}
														ref={ref}
													/>
												)}
											/>
										</div>
										<div className="col-md-6">
											<Controller
												name="info.repassword"
												control={control}
												render={({ field: { onChange, name, value, ref } }) => (
													<InputField
														name={name}
														value={value}
														errors={errors}
														placeholder="Repassword"
														errorMessage="Enter repassword"
														type="password"
														onChange={onChange}
														ref={ref}
													/>
												)}
											/>
										</div>
									</>
								)}

								{usrType === "D" && (
									<>
										{" "}
										<div className="col-md-12 mb-3">
											<Controller
												name="info.address"
												control={control}
												render={({ field: { onChange, name, value, ref } }) => (
													<TextAreaField
														name={name}
														value={value}
														errors={errors}
														placeholder="Address"
														errorMessage="Enter address"
														onChange={onChange}
														ref={ref}
													/>
												)}
											/>
										</div>
										<div className="col-md-6">
											<Controller
												name="info.abn_number"
												control={control}
												render={({ field: { onChange, name, value, ref } }) => (
													<InputField
														name={name}
														value={value}
														errors={errors}
														placeholder="ABN Number"
														errorMessage="Enter ABN Number"
														type="text"
														onChange={onChange}
														ref={ref}
													/>
												)}
											/>
										</div>
										<div className="col-md-6">
											<Controller
												name="info.tfn_number"
												control={control}
												render={({ field: { onChange, name, value, ref } }) => (
													<InputField
														name={name}
														value={value}
														errors={errors}
														placeholder="TFN Number"
														errorMessage="Enter TFN Number"
														type="text"
														onChange={onChange}
														ref={ref}
													/>
												)}
											/>
										</div>
									</>
								)}
								<div className="col-md-6">
									<Controller
										name="info.gender"
										control={control}
										render={({ field: { onChange, value, name, ref } }) => (
											<SelectField
												name={name}
												value={value}
												optionsItems={optionsForGender}
												errors={errors}
												//isDisabled={true}
												placeholder="Please select gender"
												errorMessage="Please select gender."
												ref={ref}
												onChange={onChange}
											/>
										)}
									/>
								</div>
							</div>
						</div>
						<div className="d-flex justify-content-end mt-3">
							<OutlineButton
								label={`${isEdit ? "Edit" : "Add"} User`}
								//onClick={addUser}
							/>
						</div>
					</form>
				</div>
			</div>
		</ModalBox>
	);
});
export default AddUser;
