import { createGlobalStyle, styled } from "styled-components";

export const GlobalStyle = createGlobalStyle`
body {
    background: aliceblue;
    font-family: 'Roboto', sans-serif;
}
`;
export const Container = styled.div`
  .login-box {
    border-radius: 5%;
    margin-top: 6rem;
    height: auto;
    background: #ffffff;
    text-align: center;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }

  .login-key {
    font-size: 80px;
    line-height: 100px;
    background: -webkit-linear-gradient(#27ef9f, #0db8de);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .login-title {
    margin-top: 15px;
    text-align: center;
    font-size: 20px;
    letter-spacing: 2px;
    margin-top: 15px;
    font-weight: bold;
    color: #94989d;
  }
  .company-title {
    text-align: center;
    font-size: 25px;
    font-weight: 500;
    color: #000204;
  }

  .btn-outline-primary {
    border-color: var(--primary);
    color: var(--primary);
    border-radius: 0px;
    font-weight: bold;
    letter-spacing: 1px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }

  .btn-outline-primary:hover {
    background-color: var(--primary);
    right: 0px;
    color: #ffff;
    // font-size: 17px;
  }

  .login-btm {
    float: left;
  }

  .login-button {
    padding-right: 0px;
    text-align: right;
    margin-bottom: 25px;
  }

  .login-text {
    text-align: left;
    padding-left: 0px;
    color: #a2a4a4;
  }

  .loginbttm {
    padding: 0px;
  }
  .form-horizontal .btn:hover,
  .form-horizontal .btn:focus {
    color: #fff;
    letter-spacing: 4px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  }
  .password {
    position: relative;
  }
  .eye {
    position: absolute;
    right: 16px;
    top: 17px;
    transform: translateY(-50%);
    transition: top 0.3s ease, color 0.3s ease, font-size 0.3s ease;
  }
`;
export const LogoContainer = styled.span`
  .animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }

  @-webkit-keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      -webkit-transform: translateY(0);
    }
    40% {
      -webkit-transform: translateY(-30px);
    }
    60% {
      -webkit-transform: translateY(-15px);
    }
  }

  @keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
  }

  .bounce {
    -webkit-animation-name: bounce;
    animation-name: bounce;
  }
`;
