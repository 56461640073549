import React from "react";
import Select from "react-select"; // new version of react-select
import styled from "styled-components";
import getErrorField from "../../../util";

const Container = styled.div`
  .css-48brv-control {
    border: 1px solid #ddd;
    border-radius: 10px;
    font-size: 14px;
    height: 2.8rem;
  }

  .css-t3ipsp-control:hover {
    border: 1px solid #ddd;
  }
  .css-t3ipsp-control {
    border: 1px solid #ddd;
    box-shadow: #ddd;
  }
  margin-bottom: 6px;
`;
const NestedSelectFields = (
  {
    name,
    options,
    errors,
    value,
    isMulti = false,
    isDisabled = false,
    isSearchable = false,
    placeholder,
    errorMessage,
    className,
    type,
    onChange,
    ...rest
  },
  ref
) => {
  return (
    <Container>
      <div className="nested-div">
        <Select
          options={options}
          name={name}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          isDisabled={isDisabled}
          className={className}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              // className: className,
              borderColor:
                errors && getErrorField(errors, name) ? "#008000" : "",
              boxShadow: "none",
            }),
          }}
          id={name}
        />
      </div>
    </Container>
  );
};
export default NestedSelectFields;
