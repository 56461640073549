import { createSlice } from "@reduxjs/toolkit";

import { getSelector } from "./helper";

export const tostSlice = createSlice({
  name: "tostSlice",
  initialState: {
    tostInfo: {
      status: false,
      isModal: false,
      isLoading: false,
      isModalCLose: false,
      isNotification: false,
      type: "",
      message: "",
    },
  },
  reducers: {
    requestApi: (state) => {
      state.tostInfo.isLoading = true;
      state.tostInfo.isModalCLose = true;
    },
    success: (
      state,
      {
        payload: {
          isModal,
          isModalCLose,
          isLoading,
          isErorModalCLose,
          msg,
          isNotification,
        },
      }
    ) => {
      state.tostInfo.status = true;
      state.tostInfo.isModal = isModal;
      state.tostInfo.isModalCLose = isModalCLose;
      state.tostInfo.isNotification = isNotification;
      state.tostInfo.isLoading = isLoading;
      state.tostInfo.type = "success";
      state.tostInfo.isErorModalCLose = isErorModalCLose;

      state.tostInfo.message = msg;
    },
    error: (state, action) => {
      state.tostInfo.isModal = true;
      state.tostInfo.isModalCLose = false;
      state.tostInfo.isErorModalCLose = false;
      state.tostInfo.isNotification = action?.payload?.isNotification;
      state.tostInfo.isLoading = false;
      state.tostInfo.status = true;
      state.tostInfo.type = "error";
      state.tostInfo.message = action?.payload?.response;
    },
    info: (state, action) => {
      state.tostInfo.status = true;
      state.tostInfo.isModal = false;
      state.tostInfo.isModalCLose = false;
      state.tostInfo.type = "info";
      state.tostInfo.isErorModalCLose = false;
      state.tostInfo.isNotification = false;
      state.tostInfo.message = action.payload;
    },
    modalIfo: (
      state,
      {
        payload: {
          isModal,
          isModalCLose,
          isErorModalCLose,
          isLoading,
          msg,
          isNotification,
        },
      }
    ) => {
      state.tostInfo.status = true;
      state.tostInfo.isModal = isModal;
      state.tostInfo.isLoading = isLoading;
      state.tostInfo.isErorModalCLose = isErorModalCLose;
      state.tostInfo.isNotification = isNotification;

      state.tostInfo.isModalCLose = isModalCLose;
      state.tostInfo.type = "modalIfo";
      state.tostInfo.message = msg;
    },
    clear: (state) => {
      state.tostInfo.status = false;
      state.tostInfo.isModal = false;
      state.tostInfo.isModalCLose = false;
      state.tostInfo.isErorModalCLose = false;
      state.tostInfo.isNotification = false;
      state.tostInfo.type = "";
      state.tostInfo.message = "";
    },
    clearNotification: (state) => {
      state.tostInfo.isNotification = false;
      state.tostInfo.type = "";
      state.tostInfo.message = "";
    },
    clearModal: (state) => {
      state.tostInfo.isModalCLose = false;
    },
  },
});

export const {
  success,
  error,
  info,
  clear,
  clearNotification,
  clearModal,
  modalIfo,
  requestApi,
} = tostSlice.actions;
export const selectTostInfo = getSelector("tostSlice", "tostInfo");
export default tostSlice.reducer;
