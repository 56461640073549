import React, { useEffect, useRef, useState } from "react";
import AGTable from "../../../../components/DesignSystem/AGTable";
import { columnDefs, defaultColDef } from "./Header";

import { Typography } from "../../../../components/DesignSystem/Typography";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { restoreProduct } from "../../../../action/trashAction";
import { getTrashProducts } from "../../../../redux/trashSlice";
const Container = styled.div.attrs(() => ({
  className: "",
}))`
  .ag-root-wrapper {
    border-radius: unset;
  }
`;
const Product = () => {
  const dispatch = useDispatch();
  const selectProductsDet = useSelector(getTrashProducts);

  const handleRestore = (id) => {
    dispatch(restoreProduct({ id: id, status: "A" }));
  };
  return (
    <Container>
      <div className="d-flex justify-content-between mb-2">
        <Typography align="text-start">Product Listing</Typography>
      </div>

      <AGTable
        handleRestore={handleRestore}
        rowData={selectProductsDet}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
      />
    </Container>
  );
};
export default Product;
