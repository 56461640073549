import  { useCallback } from 'react';

import { useNavigate } from 'react-router';

export const useMyNavigation = () => {
  const navigate = useNavigate();

  const push = useCallback((to, state) => navigate(to, { state }), [navigate]);

  const replace = useCallback((to, state) => navigate(to, { replace: true, state }), [navigate]);

  const go = useCallback((delta) => navigate(delta), [navigate]);

  const back = useCallback(() => navigate(-1), [navigate]);

  const forward = useCallback(() => navigate(1), [navigate]);

  
  return {
    back,
    forward,
    go,
    push,
    replace,
  };
};

;