import React, { useEffect, useRef } from "react";
import AGTable from "../../../../components/DesignSystem/AGTable";
import { SubColumnDefs, defaultColDef } from "./Header";

import { useDispatch, useSelector } from "react-redux";
import {
  deleteCategory,
  fetchCategoryDetails,
  fetchProductCategoryList,
  updateCategoryStatus,
} from "../../../../action";
import {
  clearCategory,
  selectSubCategory,
} from "../../../../redux/categorySlice";
import { Typography } from "../../../../components/DesignSystem/Typography";
import { OutlineButton } from "../../../../components/DesignSystem/Buttons";
import AddEdit from "./AddEdit";

const Subcategory = () => {
  const addCategoryModal = useRef();
  const dispatch = useDispatch();
  const getSubCategories = useSelector(selectSubCategory);
  useEffect(() => {
    dispatch(clearCategory());
    dispatch(fetchCategoryDetails());
    dispatch(fetchProductCategoryList());
  }, [dispatch]);

  const handleDelete = (id) => {
    dispatch(deleteCategory({ id: id }));
  };
  const changeStatus = (item = "") => {
    dispatch(
      updateCategoryStatus({ id: item?.data?.id, status: item?.data?.status })
    );
  };

  const addCategory = () => {
    addCategoryModal.current.handleAddCategory();
  };
  const handleEdit = (item) => {
    addCategoryModal.current.handleAddCategory(item);
  };

  return (
    <>
      <div className="d-flex justify-content-between m-2">
        <Typography align="text-start">Sub Category Listing</Typography>
        <OutlineButton label="Add Sub Category" onClick={addCategory} />
      </div>

      <AGTable
        handleEdit={handleEdit}
        handleDelete={handleDelete}
        changeStatus={changeStatus}
        columnDefs={SubColumnDefs}
        rowData={getSubCategories}
        defaultColDef={defaultColDef}
      />
      <AddEdit ref={addCategoryModal} />
    </>
  );
};
export default Subcategory;
