import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../service";
import TostAction from "./toastAction";
import { requestApi } from "../redux/tostSlice";
import { dispatchErrorToastAction } from "./commonFunction";

export const addNotification = createAsyncThunk(
  "notificationSlice/addNotification",
  async (object, { dispatch, rejectWithValue }) => {
    try {
      dispatch(requestApi());
      const param = {
        method: "post",
        endpoint: "notification",
        object: object,
      };

      const retunThunk = request(param);
      retunThunk
        .then((res) => {
          TostAction(dispatch, res, 1, 0, 0, 1);
          dispatch(fetchNotificationDetails());
        })
        .catch((error) => dispatchErrorToastAction(error, dispatch));
      return retunThunk;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchNotificationDetails = createAsyncThunk(
  "notificationSlice/fetchNotificationDetails",
  async (queryparam, { dispatch, rejectWithValue }) => {
    try {
      dispatch(requestApi());
      const param = {
        method: "get",
        endpoint: "notification",
        id: queryparam,
      };

      const retunThunk = request(param);
      retunThunk
        .then((res) => {
          TostAction(dispatch, res, 0, 0, 0);
        })
        .catch((error) => dispatchErrorToastAction(error, dispatch));
      return retunThunk;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteNotification = createAsyncThunk(
  "notificationSlice/deleteNotification",
  async (object, { dispatch, rejectWithValue }) => {
    try {
      dispatch(requestApi());
      const param = {
        method: "post",
        endpoint: "deleteNotification",
        object: object,
      };

      const retunThunk = request(param);
      retunThunk
        .then((res) => {
          TostAction(dispatch, res, 1, 0, 0, 1);
          dispatch(fetchNotificationDetails());
        })
        .catch((error) => dispatchErrorToastAction(error, dispatch));
      return retunThunk;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const updateNotificationStatus = createAsyncThunk(
  "notificationSlice/updateNotificationStatus",
  async (object, { dispatch, rejectWithValue }) => {
    try {
      dispatch(requestApi());
      const param = {
        method: "post",
        endpoint: "updateNotificationStatus",
        object: object,
      };

      const retunThunk = request(param);
      retunThunk
        .then((res) => {
          TostAction(dispatch, res, 1, 0, 0, 1);
          dispatch(fetchNotificationDetails());
        })
        .catch((error) => dispatchErrorToastAction(error, dispatch));
      return retunThunk;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const sendNotification = createAsyncThunk(
  "notificationSlice/sendNotification",
  async (object, { dispatch, rejectWithValue }) => {
    try {
      dispatch(requestApi());
      const param = {
        method: "post",
        endpoint: "sendNotification",
        object: object,
      };

      const retunThunk = request(param);
      retunThunk
        .then((res) => {
          TostAction(dispatch, res, 1, 0, 0, 1);
          dispatch(fetchNotificationDetails());
        })
        .catch((error) => dispatchErrorToastAction(error, dispatch));
      return retunThunk;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
