import styled from "styled-components";

export const TextBoxContainer = styled.div.attrs(() => ({
  className: "",
}))`
  .form-control {
    height: 2.8rem;
  }
  .custom-field {
    position: relative;
    font-size: 14px;
    margin-bottom: 5px;
    --field-padding: 5px 12px;
  }

  .custom-field input {
    border: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #f2f2f2;
    padding: var(--field-padding);
    border-radius: 10px;
    outline: none;
    font-size: 14px;
  }

  .custom-field .placeholders {
    position: absolute;
    left: var(--field-padding);
    width: calc(100% - (var(--field-padding) * 2));
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    top: 21px;
    transform: translateY(-50%);
    border-radius: 20px;
    transition: top 0.3s ease, color 0.3s ease, font-size 0.3s ease;
  }

  .custom-field input.dirty + .placeholders,
  .custom-field input:focus + .placeholders,
  .custom-field input:not(:placeholder-shown) + .placeholders {
    top: -10px;
    font-size: 14px;
    color: #ddd;
  }

  .custom-field .error-message {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 8px;
    font-size: 12px;
    background: #d30909;
    color: #fff;
    height: 24px;
  }

  .custom-field .error-message:empty {
    opacity: 0;
  }

  .custom-field.one input {
    background: none;
    border: 1px solid #ddd;
    transition: border-color 0.3s ease;
  }

  .custom-field.one input + .placeholders {
    left: 8px;
    padding: 0 5px;
  }

  .custom-field.one input.dirty,
  .custom-field.one input:not(:placeholder-shown),
  .custom-field.one input:focus {
    border-color: #ddd;
    transition-delay: 0.1s;
  }

  .custom-field.one input.dirty + .placeholders,
  .custom-field.one input:not(:placeholder-shown) + .placeholders,
  .custom-field.one input:focus + .placeholders {
    top: 0;
    font-size: 13px;
    color: #908686;
    background: #ffffff;
    width: auto;
  }
`;
export const TextAreaContainer = styled.div.attrs(() => ({
  className: "",
}))`
  .custom-field {
    position: relative;
    font-size: 14px;
    margin-bottom: 5px;
    --field-padding: 12px 5px;
  }

  .custom-field textarea {
    border: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #f2f2f2;
    padding: var(--field-padding);
    border-radius: 10px;
    outline: none;
    font-size: 14px;
  }

  .custom-field .placeholders {
    position: absolute;
    left: var(--field-padding);
    width: calc(100% - (var(--field-padding) * 2));
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    top: 22px;
    transform: translateY(-50%);
    transition: top 0.3s ease, color 0.3s ease, font-size 0.3s ease;
  }

  .custom-field textarea.dirty + .placeholders,
  .custom-field textarea:focus + .placeholders,
  .custom-field textarea:not(:placeholder-shown) + .placeholders {
    top: -10px;
    font-size: 14px;
    color: #ddd;
  }

  .custom-field .error-message {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 8px;
    font-size: 12px;
    background: #d30909;
    color: #fff;
    height: 24px;
  }

  .custom-field .error-message:empty {
    opacity: 0;
  }

  .custom-field.one textarea {
    background: none;
    border: 1px solid #ddd;
    transition: border-color 0.3s ease;
  }

  .custom-field.one textarea + .placeholders {
    left: 8px;
    padding: 0 5px;
  }

  .custom-field.one textarea.dirty,
  .custom-field.one textarea:not(:placeholder-shown),
  .custom-field.one textarea:focus {
    border-color: #ddd;
    transition-delay: 0.1s;
  }

  .custom-field.one textarea.dirty + .placeholders,
  .custom-field.one textarea:not(:placeholder-shown) + .placeholders,
  .custom-field.one textarea:focus + .placeholders {
    top: 0;
    font-size: 14px;
    color: #908686;
    background: #ffffff;

    width: auto;
  }
`;
