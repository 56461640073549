import { createSlice } from "@reduxjs/toolkit";
import { getSelector } from "./helper";
import {
  fetchInventoryLowStockDetails,
  fetchInventorySellingProductsDetails,
} from "../action";
import {
  lowStockNormalizer,
  outOfStockNormalizer,
  sellingProductsNormalizer,
} from "../Normalization/NormalizerSetUp";

const inventorySlice = createSlice({
  name: "inventoryMng",
  initialState: {
    inventoryLowStockDetails: [],
    inventoryOutOfStockProductsDetails: [],
    inventorySellingProductsDetails: [],
    loading: false,
    isSuccess: false,
    message: "",
  },
  reducers: {
    clear: (state) => {
      state.status = false;
      state.data = [];
      state.loading = false;
    },
  },
  extraReducers: {
    [fetchInventoryLowStockDetails.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchInventoryLowStockDetails.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.inventoryLowStockDetails = lowStockNormalizer(payload?.items);
      state.inventoryOutOfStockProductsDetails = outOfStockNormalizer(
        payload?.items
      );
    },
    [fetchInventoryLowStockDetails.rejected]: (state, action) => {
      state.loading = false;
    },
    [fetchInventorySellingProductsDetails.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchInventorySellingProductsDetails.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.inventorySellingProductsDetails = sellingProductsNormalizer(
        payload?.items
      );
    },
    [fetchInventorySellingProductsDetails.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export const { clear } = inventorySlice.actions;

export const selectInventoryLowStockInfo = getSelector(
  "inventorySlice",
  "inventoryLowStockDetails"
);
export const selectInventoryOutOfStockInfo = getSelector(
  "inventorySlice",
  "inventoryOutOfStockProductsDetails"
);
export const selectInventorySellingProductsInfo = getSelector(
  "inventorySlice",
  "inventorySellingProductsDetails"
);
export default inventorySlice.reducer;
