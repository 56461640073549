import React from "react";

import { useSelector } from "react-redux";
import { selectUsersTypeDetailsInfo } from "../../redux/userSlice";
import AGBarChart from "../../components/DesignSystem/AGBarChart";
import { BarChartSeries, PieChartSeries } from "./ChartSeries";
import AGPieChart from "../../components/DesignSystem/AGPieChart";
import { Typography } from "../../components/DesignSystem/Typography";
import { selectOrderReportsInfo } from "../../redux/orderSlice";
import SearchBar from "./SearchBar";
import StockAlertBox from "./StockAlertBox";

const Dashboard = () => {
  const getUserTypeDetails = useSelector(selectUsersTypeDetailsInfo);
  const getOrderReportsDetails = useSelector(selectOrderReportsInfo);

  return (
    <>
      <div className="d-flex justify-content-between mb-2">
        <Typography align="text-start">Dashboard</Typography>
      </div>
      <div className="px-2">
        <div className="row m-2">
          <div className="col-md-12">
            <SearchBar />
          </div>

          <div className="col-md-8 ">
            <AGBarChart
              getOrderReportsDetails={getOrderReportsDetails}
              barChartSeries={BarChartSeries}
              title="Order Report"
            />
          </div>
          <div className="col-md-4">
            <AGPieChart
              getUserTypeDetails={getUserTypeDetails}
              pieChartSeries={PieChartSeries}
            />
          </div>
        </div>
        <StockAlertBox />
      </div>
    </>
  );
};
export default Dashboard;
