import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { columns, defColumnDefs } from "./Header";
import { selectInventoryLowStockInfo } from "../../../../redux/inventorySlice";
import AddStock from "../AddStock";
import AGNestedTable from "../../../../components/DesignSystem/AGNestedTable";
import DeatailsComponent from "../DeatailsComponent";

const Lowstock = () => {
	const addStockModal = useRef();
	const editStock = (item) => {
		addStockModal.current.handleAddStock(item);
	};
	const getLowStockDetails = useSelector(selectInventoryLowStockInfo);

	return (
		<>
			<AGNestedTable
				rowData={getLowStockDetails}
				columnDefs={columns}
				defaultColDef={defColumnDefs}
				detailCellRenderer={(props) => (
					<DeatailsComponent {...props} editStock={editStock} />
				)}
			/>

			<AddStock ref={addStockModal} />
		</>
	);
};
export default Lowstock;
