import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../service";
import TostAction from "./toastAction";
import { requestApi } from "../redux/tostSlice";
import { dispatchErrorToastAction } from "./commonFunction";

export const addCategory = createAsyncThunk(
  "categorySlice/addCategory",
  async (object, { dispatch, rejectWithValue }) => {
    try {
      dispatch(requestApi());
      const param = {
        method: "post",
        endpoint: "category",
        object: object,
      };

      const retunThunk = request(param);
      retunThunk
        .then((res) => {
          TostAction(dispatch, res, 1, 0, 0, 1);
          dispatch(fetchCategoryDetails());
        })
        .catch((error) => dispatchErrorToastAction(error, dispatch));
      return retunThunk;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchCategoryDetails = createAsyncThunk(
  "categorySlice/fetchCategoryDetails",
  async (queryparam, { dispatch, rejectWithValue }) => {
    try {
      dispatch(requestApi());
      const param = {
        method: "get",
        endpoint: "category",
        filter: queryparam,
      };

      const retunThunk = request(param);
      retunThunk
        .then((res) => {
          TostAction(dispatch, res, 0, 0, 0);
        })
        .catch((error) => dispatchErrorToastAction(error, dispatch));
      return retunThunk;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const getSubCategoryById = createAsyncThunk(
  "categorySlice/getSubCategoryById",
  async (object, { dispatch, rejectWithValue }) => {
    try {
      dispatch(requestApi());
      const param = {
        method: "post",
        endpoint: "getSubCategory",
        object: object,
      };

      const retunThunk = request(param);
      retunThunk
        .then((res) => {
          TostAction(dispatch, res, 0, 0, 0);
        })
        .catch((error) => dispatchErrorToastAction(error, dispatch));
      return retunThunk;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteCategory = createAsyncThunk(
  "CategorySlice/deleteCategory",
  async (object, { dispatch, rejectWithValue }) => {
    try {
      dispatch(requestApi());
      const param = {
        method: "post",
        endpoint: "deleteCategory",
        object: object,
      };

      const retunThunk = request(param);
      retunThunk
        .then((res) => {
          TostAction(dispatch, res, 1, 0, 0, 1);
          dispatch(fetchCategoryDetails());
        })
        .catch((error) => dispatchErrorToastAction(error, dispatch));
      return retunThunk;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const updateCategoryStatus = createAsyncThunk(
  "CategorySlice/updateCategoryStatus",
  async (object, { dispatch, rejectWithValue }) => {
    try {
      dispatch(requestApi());
      const param = {
        method: "post",
        endpoint: "updateCategoryStatus",
        object: object,
      };

      const retunThunk = request(param);
      retunThunk
        .then((res) => {
          TostAction(dispatch, res, 1, 0, 0, 1);
          dispatch(fetchCategoryDetails());
        })
        .catch((error) => dispatchErrorToastAction(error, dispatch));
      return retunThunk;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
