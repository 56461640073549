import React, { useMemo, useState } from "react";
const getDate = (value) => {
  var dateParts = value.split("/");
  return new Date(
    Number(dateParts[2]),
    Number(dateParts[1]) - 1,
    Number(dateParts[0])
  );
};
// {params.value}

const dateFilterParams = {
  filters: [
    {
      filter: "agDateColumnFilter",
      filterParams: {
        comparator: (filterDate, cellValue) => {
          if (cellValue == null) return -1;
          return getDate(cellValue).getTime() - filterDate.getTime();
        },
      },
    },
    {
      filter: "agSetColumnFilter",
      filterParams: {
        comparator: (a, b) => {
          return getDate(a).getTime() - getDate(b).getTime();
        },
      },
    },
  ],
};
export const columnDefs = [
  {
    headerName: "Product Name",
    field: "product_name",
    filter: "agMultiColumnFilter",
  },
  // {
  //   headerName: "Parent Image",
  //   field: "image",
  //   cellRenderer: (params) => {
  //     return <>{params?.data?.parent?.category_name}</>;
  //   },
  // },
  //   {
  //     field: "Image",
  //     cellRenderer: (params) => {
  //       return (
  //         <>
  //           <img
  //             style={{ width: "40px", borderRadius: "50%" }}
  //             src={`${process.env.REACT_APP_BASE_URL}${params.data.image}`}
  //           />
  //         </>
  //       );
  //     },
  //   },

  // {
  // 	field: "Status",
  // 	cellRenderer: (params) => {
  // 		return (
  // 			<>
  // 				{params.data.status === "A" ? (
  // 					<div className="btn btn-outline-primary btn-sm">Active</div>
  // 				) : (
  // 					<div className="btn btn-outline-warning btn-sm">Pending</div>
  // 				)}
  // 			</>
  // 		);
  // 	},
  // },

  {
    headerName: "Created Date",
    field: "created_at",
    filter: "agMultiColumnFilter",
    filterParams: dateFilterParams,
  },
];
export const defaultColDef = {
  flex: 1,
  minWidth: 200,
  menuTabs: ["filterMenuTab"],
};

export const columns = [
  {
    headerName: "Product No",
    field: "product_no",
    cellRenderer: "agGroupCellRenderer",
  },
  {
    headerName: "Product Name",
    field: "product_name",
    cellRenderer: "agGroupCellRenderer",
    //checkboxSelection: true,
    //headerCheckboxSelection: true,
  },
  // {
  //   field: "Image",
  //   cellRenderer: (params) => {
  //     return (
  //       <>
  //         <img
  //           style={{ width: "40px", height: "40px", borderRadius: "50%" }}
  //           src={`${process.env.REACT_APP_BASE_URL}/public${params.data.image}`}
  //         />
  //       </>
  //     );
  //   },
  // },

  { headerName: "Quantity Alert", field: "quantity_alert" },
  { headerName: "Created Date", field: "created_at" },
];

export const defColumnDefs = {
  flex: 1,
  minWidth: 200,
  menuTabs: ["filterMenuTab"],
};
