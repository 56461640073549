import React, { useEffect, useRef } from "react";
import { columnDefs, defaultColDef } from "./Header";
import AGTable from "../../../components/DesignSystem/AGTable";
import { useDispatch, useSelector } from "react-redux";

import { Typography } from "../../../components/DesignSystem/Typography";
import { fetchOrderDetails } from "../../../action";
import { selectOrderDetailsInfo } from "../../../redux/orderSlice";
import ViewOrder from "./ViewOrder";
import { useMyNavigation } from "../../../hooks";
import OrderChangeStatus from "./ViewOrder/OrderChangeStatus";

const Order = () => {
  const dispatch = useDispatch();
  const orderChangeModal = useRef();
  const selectOrderDet = useSelector(selectOrderDetailsInfo);
  const { push } = useMyNavigation();
  const handleView = (item) => {
    push("/order-details", { item });
    //viewOrderModal.current.handleViewOrder(item);
  };
  useEffect(() => {
    dispatch(fetchOrderDetails());
  }, []);
  const handleOrderStatus = (item) => {
    orderChangeModal.current.handleOrderChange(item.order_status, item.id, "M");
  };

  return (
    <>
      <div className="d-flex justify-content-between mb-2">
        <Typography align="text-start">Order Listing</Typography>
      </div>
      <AGTable
        handleView={handleView}
        rowData={selectOrderDet}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        handleOrderStatus={handleOrderStatus}
      />
      <OrderChangeStatus ref={orderChangeModal} />
      {/* <ViewOrder ref={viewOrderModal} /> */}
    </>
  );
};
export default Order;
