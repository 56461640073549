import React from "react";
import { ListIcon } from "../DesignSystem/Icons/Icons";
import { superAdminMenuItems } from "../../config/nav-config";
import { useMyNavigation } from "../../hooks";
import { getPageUrl } from "../../util";

export const NavBar = ({ setOpen, open }) => {
	const path = window.location;

	const logoImage = require(`../../images/logo.png`);
	const { replace } = useMyNavigation();

	return (
		<>
			<nav className="navbar nav-header ">
				<div className="d-flex justify-content-between navbar-brand mx-3" href>
					<div className="nav-header cp flex-grow-1">
						<img
							src={logoImage}
							alt="..."
							style={{ width: "3.2rem" }}
							className="rounded"
						/>
						<span style={{ margin: "5px", fontSize: "1.4rem" }}>
							{" "}
							Admin Panel{" "}
						</span>
					</div>

					<div className="menu-icon" onClick={() => setOpen(!open)}>
						<ListIcon />
					</div>
				</div>
				<div className="divider "></div>
			</nav>
			<div className="d-flex flex-column nav-items gap-1 mt-4">
				{superAdminMenuItems?.map(({ icon, label, url, url2, url3 }, key) => (
					<div
						key={key}
						className={`nav-item cp  border-bottom py-2  ${
							getPageUrl(path) === url ||
							getPageUrl(path) === url2 ||
							getPageUrl(path) === url3
								? "active"
								: ""
						}`}
						onClick={() => replace(url)}
					>
						{icon} &nbsp;<span> {label}</span>
					</div>
				))}
			</div>
		</>
	);
};
//   {
//     superAdminMenuItems?.map(({ icon, label }) => {
//       <div className="nav-item cp shadow-sm border-bottom">
//         {icon} {label}
//         {/* <img src={logoImage} alt="..." /> */}
//       </div>;
//     });
//   }
