import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../service";
import TostAction from "./toastAction";
import { requestApi } from "../redux/tostSlice";
import { dispatchErrorToastAction } from "./commonFunction";

export const addPayment = createAsyncThunk(
  "paymentSlice/addPayment",
  async (object, { dispatch, rejectWithValue }) => {
    try {
      dispatch(requestApi());
      const param = {
        method: "post",
        endpoint: "payment",
        object: object,
      };

      const retunThunk = request(param);
      retunThunk
        .then((res) => {
          TostAction(dispatch, res, 1, 0, 0, 1);
          dispatch(fetchPaymentDetails());
        })
        .catch((error) => dispatchErrorToastAction(error, dispatch));

      return retunThunk;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchPaymentDetails = createAsyncThunk(
  "paymentSlice/fetchPaymentDetails",
  async (queryparam, { dispatch, rejectWithValue }) => {
    try {
      dispatch(requestApi());
      const param = {
        method: "get",
        endpoint: "payment",
        id: queryparam,
      };

      const retunThunk = request(param);
      retunThunk
        .then((res) => {
          TostAction(dispatch, res, 0, 0, 0);
        })
        .catch((error) => dispatchErrorToastAction(error, dispatch));
      return retunThunk;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
