import { createSlice } from "@reduxjs/toolkit";
import { getSelector } from "./helper";
import { fetchTrashCategoryDetails, fetchTrashProductDetails } from "../action";
import { categoryNormalizer } from "../Normalization/NormalizerSetUp";

const trashSlice = createSlice({
	name: "trashSlice",
	initialState: {
		trashCategory: [],
		trashSubCategory: [],
		trashProduct: [],
		trashOrder: [],
		loading: false,
		isSuccess: false,
		message: "",
	},
	reducers: {
		clearCategory: (state) => {
			state.status = false;
			state.categoriesDetails = [];
			state.parentCategoriesDetails = [];
			state.loading = false;
		},
	},
	extraReducers: {
		[fetchTrashCategoryDetails.pending]: (state, action) => {
			state.loading = true;
		},
		[fetchTrashCategoryDetails.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.trashCategory = categoryNormalizer(payload?.items);
			state.trashSubCategory = categoryNormalizer(payload?.items, true);
		},
		[fetchTrashCategoryDetails.rejected]: (state, action) => {
			state.loading = false;
		},

		[fetchTrashProductDetails.pending]: (state, action) => {
			state.loading = true;
		},
		[fetchTrashProductDetails.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.trashProduct = payload?.items;
		},
		[fetchTrashProductDetails.rejected]: (state, action) => {
			state.loading = false;
		},
	},
});

export const { clearCategory } = trashSlice.actions;

export const getTrashCategory = getSelector("trashSlice", "trashCategory");
export const getTrashSubCategory = getSelector(
	"trashSlice",
	"trashSubCategory"
);
export const getTrashProducts = getSelector("trashSlice", "trashProduct");
export default trashSlice.reducer;
