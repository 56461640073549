import React from "react";
import { logout } from "../../util";
import {
	CartIcon,
	InfoIcon,
	ListIcon,
	LogoutIcon,
	ProfileIcon,
} from "../DesignSystem/Icons/Icons";
import { useMyNavigation } from "../../hooks/useMyNavigation";

export const Header = ({ setOpen, open }) => {
	const { go } = useMyNavigation();
	return (
		<nav className="navbar navbar-expand header h-20 navbar-dark  px-3 py-0">
			<div className="">
				<a
					href
					className={`sidebar-toggler flex-shrink-0 ${!open ? "" : ""}`}
					onClick={() => setOpen(!open)}
				>
					<i className="fa fa-bars">
						<ListIcon />
					</i>
				</a>
			</div>
			<div className="navbar-nav align-items-center ms-auto">
				<a href className="nav-link cp" onClick={() => go("/info")}>
					{/* <InfoIcon size={25} /> */}
				</a>{" "}
				<a href className="nav-link cp">
					{/* <CartIcon size={25} /> */}
				</a>
				<div className="nav-item dropdown">
					<a
						href
						className="nav-link dropdown-toggle cp"
						data-bs-toggle="dropdown"
					>
						<ProfileIcon size={30} />
					</a>
					<div className="dropdown-menu dropdown-menu-end  border-0 rounded-0 rounded-bottom m-0">
						{/* <a href className="dropdown-item cp">
							<span className="fw-normal mb-0">
								<ProfileIcon /> &nbsp; Profile
							</span>
						</a>
						<hr className="dropdown-divider" /> */}
						<a href className="dropdown-item" onClick={logout}>
							<div className="d-flex gap-2">
								<span className="fw-normal mb-0 sub-label cp">
									<LogoutIcon /> &nbsp; Logout
								</span>
							</div>
						</a>
					</div>
				</div>
			</div>
		</nav>
	);
};
