import React from "react";
const getDate = (value) => {
  var dateParts = value.split("/");
  return new Date(
    Number(dateParts[2]),
    Number(dateParts[1]) - 1,
    Number(dateParts[0])
  );
};
// {params.value}

const dateFilterParams = {
  filters: [
    {
      filter: "agDateColumnFilter",
      filterParams: {
        comparator: (filterDate, cellValue) => {
          if (cellValue == null) return -1;
          return getDate(cellValue).getTime() - filterDate.getTime();
        },
      },
    },
    {
      filter: "agSetColumnFilter",
      filterParams: {
        comparator: (a, b) => {
          return getDate(a).getTime() - getDate(b).getTime();
        },
      },
    },
  ],
};
export const columnDefs = [
  {
    headerName: "Product No",
    field: "product_no",
    filter: "agMultiColumnFilter",
  },
  {
    headerName: "Product Name",
    field: "product_name",
    filter: "agMultiColumnFilter",
  },
  // {
  //   headerName: "Parent Image",
  //   field: "image",
  //   cellRenderer: (params) => {
  //     return <>{params?.data?.parent?.category_name}</>;
  //   },
  // },

  {
    headerName: "Created Date",
    field: "created_at",
    filter: "agMultiColumnFilter",
    filterParams: dateFilterParams,
  },
];
export const defaultColDef = {
  flex: 1,
  minWidth: 200,
  menuTabs: ["filterMenuTab"],
};

export const columns = [
  {
    headerName: "Product No",
    field: "product_no",
    cellRenderer: "agGroupCellRenderer",
  },
  {
    headerName: "Product Name",
    field: "product_name",
    cellRenderer: "agGroupCellRenderer",
    //checkboxSelection: true,
    //headerCheckboxSelection: true,
  },
  // {
  //   field: "Image",
  //   cellRenderer: (params) => {
  //     return (
  //       <div className="text-start">
  //         <img
  //           style={{ width: "40px", height: "40px", borderRadius: "50%" }}
  //           src={`${process.env.REACT_APP_BASE_URL}/public${params.data.image}`}
  //         />
  //       </div>
  //     );
  //   },
  // },

  { headerName: "Quantity Alert", field: "quantity_alert" },
  { headerName: "Created Date", field: "created_at" },
];

export const defColumnDefs = {
  flex: 1,
  minWidth: 200,
  menuTabs: ["filterMenuTab"],
};
