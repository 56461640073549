import * as Yup from "yup";
const regex =
	// eslint-disable-next-line no-useless-escape
	/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const validatePhone = (phone) => {
	return Yup.number()
		.integer()
		.positive()
		.test((phone) => {
			return phone && phone.toString().length === 10 ? true : false;
		})
		.isValidSync(phone);
};

export const UserShema = Yup.object().shape({
	info: Yup.object().shape({
		email: Yup.string()
			.required("")
			.test("", (value) => {
				return regex.test(value);
			}),
		mobile_no: Yup.string()
			.required("")
			.test("", (value) => {
				return validatePhone(parseInt(value ?? "0"));
			}),
		first_name: Yup.string().required(""),
		last_name: Yup.string().required(""),
		user_type: Yup.string().required(""),
		gender: Yup.object().shape({
			value: Yup.string().required("Required"),
			label: Yup.string().required("Required"),
		}),
		//password: Yup.string().required(""),
		// password: Yup.string()
		// 	.required("Password is required")
		// 	.matches(
		// 		/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*[\]{}()?"\\,><':;|_~`=+-])[a-zA-Z\d!@#$%^&*[\]{}()?"\\,><':;|_~`=+-]{6,99}$/,
		// 		"Must contain at least 6 Characters, 1 Uppercase, 1 Lowercase, 1 Special Character, and 1 Number"
		// 	),
		// repassword: Yup.string().oneOf(
		// 	[Yup.ref("password"), null],
		// 	"Passwords must match"
		// ),

		address: Yup.string().when("user_type", {
			is: "D",
			then: () => Yup.string().required(""),
			otherwise: () => Yup.string().notRequired(),
		}),
		abn_number: Yup.string().when("user_type", {
			is: "D",
			then: () => Yup.string().required(""),
			otherwise: () => Yup.string().notRequired(),
		}),
		tfn_number: Yup.string().when("user_type", {
			is: "D",
			then: () => Yup.string().required(""),
			otherwise: () => Yup.string().notRequired(),
		}),
	}),
});
