import React from "react";
import { Route, Routes } from "react-router-dom";
import { SuperAdminRouters } from "./SuperAdminRouters";

export const Routers = () => {
  return (
    <Routes>
      <Route exact path="/*" element={<SuperAdminRouters />} />
    </Routes>
  );
};
