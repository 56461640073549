import React, { useEffect, useRef } from "react";
import AGTable from "../../../components/DesignSystem/AGTable";
import { columnDefs, defaultColDef } from "./Header";
import { OutlineButton } from "../../../components/DesignSystem/Buttons";

import { Typography } from "../../../components/DesignSystem/Typography";
import { useDispatch, useSelector } from "react-redux";
import {
	deleteBanner,
	fetchBannerDetails,
	updateBannerStatus,
} from "../../../action";

import { selectBannerInfo } from "../../../redux/bannerSlice";
import AddBanner from "./AddBanner";

const Banner = () => {
	//const [rowData, setRowData] = useState();
	const addBannerModal = useRef();
	const dispatch = useDispatch();
	const selectBannersDet = useSelector(selectBannerInfo);
	useEffect(() => {
		dispatch(fetchBannerDetails());
	}, [dispatch]);

	const handleDelete = (id) => {
		dispatch(deleteBanner({ id: id }));
	};
	const changeStatus = (item = "") => {
		dispatch(
			updateBannerStatus({ id: item?.data?.id, status: item?.data?.status })
		);
	};

	const addBanner = () => {
		addBannerModal.current.handleAddBanner();
	};
	const handleEdit = (item) => {
		addBannerModal.current.handleAddBanner(item);
	};
	return (
		<>
			<div className="d-flex justify-content-between mb-2">
				<Typography align="text-start">Banner Listing</Typography>
				<OutlineButton label="Add Banner" onClick={addBanner} />
			</div>
			<AGTable
				handleEdit={handleEdit}
				handleDelete={handleDelete}
				changeStatus={changeStatus}
				rowData={selectBannersDet}
				columnDefs={columnDefs}
				defaultColDef={defaultColDef}
			/>
			<AddBanner ref={addBannerModal} />
		</>
	);
};
export default Banner;
