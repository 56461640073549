import React, { useMemo, useState } from "react";
const getDate = (value) => {
	var dateParts = value.split("/");
	return new Date(
		Number(dateParts[2]),
		Number(dateParts[1]) - 1,
		Number(dateParts[0])
	);
};
// {params.value}

const dateFilterParams = {
	filters: [
		{
			filter: "agDateColumnFilter",
			filterParams: {
				comparator: (filterDate, cellValue) => {
					if (cellValue == null) return -1;
					return getDate(cellValue).getTime() - filterDate.getTime();
				},
			},
		},
		{
			filter: "agSetColumnFilter",
			filterParams: {
				comparator: (a, b) => {
					return getDate(a).getTime() - getDate(b).getTime();
				},
			},
		},
	],
};
export const columnDefs = [
	{
		headerName: "Name",
		field: "product_name",
		filter: "agMultiColumnFilter",
	},
	{
		headerName: "Product No",
		field: "product_no",
		filter: "agMultiColumnFilter",
	},
	// {
	//   field: "Image",
	//   cellRenderer: (params) => {
	//     return (
	//       <>
	//         <img
	//           style={{ height: "40px", width: "40px", borderRadius: "50%" }}
	//           src={`${process.env.REACT_APP_BASE_URL}/public${params.data.image}`}
	//         />
	//       </>
	//     );
	//   },
	// },
	{
		headerName: "Quantity Alert",
		field: "quantity_alert",
		filter: "agMultiColumnFilter",
	},

	{
		headerName: "Created Date",
		field: "created_at",
		filter: "agMultiColumnFilter",
		filterParams: dateFilterParams,
	},
];
export const defaultColDef = {
	flex: 1,
	minWidth: 200,
	menuTabs: ["filterMenuTab"],
};
