import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCategory,
  selectParentCategory,
} from "../../../../redux/categorySlice";
import { ParentCategoryShema } from "../../../../validationShema/CategoryShema";
import { filterObjectByValue } from "../../../../util";
import { addCategory, fetchProductCategoryList } from "../../../../action";
import ModalBox from "../../../../components/DesignSystem/ModalBox";
import {
  InputField,
  SelectField,
} from "../../../../components/DesignSystem/FormField";
import { Image } from "../../../../components/DesignSystem/Image";
import { OutlineButton } from "../../../../components/DesignSystem/Buttons";

const resData = {
  info: {
    category_name: "",
    category: "",
    image: "",
    p_id: "",
  },
};

const AddEdit = forwardRef(({}, ref) => {
  const [isEdit, setIsEdit] = useState(false);
  const getParentCategories = useSelector(selectParentCategory);
  const getCategoryDet = useSelector(selectCategory);

  //console.log({ getCategorySubcategoryDet });

  const dispatch = useDispatch();
  const openModal = useRef();
  const imageRef = useRef();
  const formOptions = {
    resolver: yupResolver(ParentCategoryShema),
  };

  const {
    formState: { errors },
    handleSubmit,
    control,
    reset,
    setValue,
  } = useForm(formOptions, {
    defaultValues: {
      ...resData,
    },
  });

  useImperativeHandle(ref, (item) => ({
    handleAddCategory(item) {
      if (item) {
        setIsEdit(true);
        setValue("info", item);
        setValue(
          "info.p_id",
          filterObjectByValue(getParentCategories, item?.p_id)
        );
        setValue(
          "info.category",
          filterObjectByValue(getCategoryDet, item?.p_id)
        );
        imageRef.current.resetImage(item?.image);
      } else {
        reset(resData);
        setIsEdit(false);
        imageRef.current.resetImage("");
      }
      openModal.current.handleModalBox();
    },
  }));

  const onSubmit = ({ info }, e) => {
    const item = {
      ...info,
      p_id: info?.p_id?.value,
      image: info?.c_image,
      category: (info?.category && info?.category?.value) || "",
      label: 1,
    };

    dispatch(addCategory(item));
    dispatch(fetchProductCategoryList());
    reset({ ...resData });
  };

  return (
    <ModalBox
      ref={openModal}
      size="lg"
      title={`${isEdit ? "Edit" : "Add"} Category`}
    >
      <div className="container p-1">
        <div className="p-2">
          <form className="box" onSubmit={handleSubmit(onSubmit)}>
            <div className="row px-2 py-3">
              <div className="col-md-6">
                <Controller
                  name="info.category_name"
                  control={control}
                  render={({ field: { onChange, name, value, ref } }) => (
                    <InputField
                      name={name}
                      value={value}
                      errors={errors}
                      placeholder="Category name"
                      errorMessage="Category name"
                      type="text"
                      onChange={onChange}
                      ref={ref}
                    />
                  )}
                />
              </div>
              <div className="col-md-6">
                <Controller
                  name="info.p_id"
                  control={control}
                  render={({ field: { onChange, value, name, ref } }) => (
                    <SelectField
                      name={name}
                      value={value}
                      optionsItems={getParentCategories}
                      errors={errors}
                      placeholder="Select parent category"
                      errorMessage="Please select category."
                      ref={ref}
                      onChange={onChange}
                    />
                  )}
                />
              </div>
              <div className="col-md-6">
                <Image
                  errors={errors}
                  control={control}
                  ref={imageRef}
                  setValue={setValue}
                  image_name="info.c_image"
                />
              </div>
            </div>
            <div className="d-flex justify-content-end mt-3">
              <OutlineButton label={`${isEdit ? "UPDATE" : "CREATE"} `} />
            </div>
          </form>
        </div>
      </div>
    </ModalBox>
  );
});
export default AddEdit;
