import {
	ArchiveIcon,
	BannerIcon,
	CategoryIcon,
	DashboardIcon,
	NotificationIcon,
	OrderIcon,
	ProductIcon,
	ProfileIcon,
	SettingIcon,
} from "../components/DesignSystem/Icons/Icons";

export const superAdminMenuItems = [
	{
		index: 1,
		label: "Dashboard",
		url: "dashboard",
		icon: <DashboardIcon />,
	},
	{
		index: 2,
		label: "Users",
		url: "users",
		icon: <ProfileIcon />,
	},
	{
		index: 3,
		label: "Category",
		url: "categories",
		icon: <CategoryIcon />,
	},
	{
		index: 4,
		label: "Products",
		url: "products",
		url2: "manage-product",
		url3: "create-product",

		icon: <ProductIcon />,
	},

	{
		index: 5,
		label: "Order",
		url: "order",
		url2: "order-details",
		icon: <OrderIcon />,
	},
	{
		index: 6,
		label: "Send Notification",
		url: "notification", //notifications
		icon: <NotificationIcon />,
	},
	{
		index: 1,
		label: "Banners",
		url: "banners",
		icon: <BannerIcon />,
	},
	{
		index: 8,
		label: "Settings",
		url: "settings",
		icon: <SettingIcon />,
	},
	{
		index: 9,
		label: "Archives",
		url: "archives",
		icon: <ArchiveIcon />,
	},
	{
		index: 10,
		label: "Inventory",
		url: "inventory",
		icon: <ArchiveIcon />,
	},
];
export const adminNavItems = [
	{ title: "Customer", value: "C" },
	{ title: "Admin", value: "A" },
	{ title: "Manager", value: "M" },
	{ title: "Delivery Rider", value: "D" },
];
export const managerNavItems = [
	{ title: "Customer", value: "C" },
	{ title: "Delivery Rider", value: "D" },
];

export const ArchiveTabs = [
	{ title: "Products", value: "P" },
	{ title: "Category", value: "C" },
	{ title: "Sub Category", value: "S" },
	{ title: "Order", value: "O" },
];
export const InventoryTabs = [
	{ title: "Low Stock", pageTitle: "Low Stock", value: "L" },
	{ title: "Out of Stock", pageTitle: "Out of Stock", value: "O" },
	{ title: "Selling Product", pageTitle: "Selling", value: "S" },
];

export const OrderStatus = [
	{ label: "Pending", value: "Pending" },
	{ label: "Processing", value: "Processing" },
	{ label: "Shipped", value: "Shipped" },
	{ label: "Delivered", value: "Delivered" },
	{ label: "Returned", value: "Returned" },
	{ label: "Canceled", value: "Canceled" },
];

export const CategoryTabs = [
	{ title: "Category", pageTitle: "Category", value: "P" },
	{ title: "Sub Category", pageTitle: "Sub Category", value: "S" },
];

export const userType = { A: "Admin", C: "Customer", M: "Manager", D: "Rider" };
