import React from "react";
// import from "react-datepicker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const DatePickers = ({ name, value, onChange, ref, minDate, maxDate }) => {
	return (
		<DatePicker
			showIcon
			selected={value}
			onChange={onChange}
			name={name}
			value={value}
			minDate={minDate}
			maxDate={maxDate}
		/>
	);
};

export default DatePickers;
