export const getPageUrl = (url) => {
	const urlSegment = url?.hash.split("/");
	return url?.hash.split("/")[urlSegment?.length - 1];
};

export const encode = (item) => {
	return btoa(btoa(String(item)));
};
export const currentYear = () => {
	return new Date().getFullYear();
};
export const dateCalculate = (startDate, endDate) => {
	const date1 = new Date(startDate);
	const date2 = new Date(endDate);
	const diffTime = Math.abs(date2 - date1);
	const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
	// console.log(diffTime + " milliseconds");
	if (date1 > date2) return false;
	return diffDays;
};

export const decode = (item) => {
	return atob(atob(String(item)));
};

export const logout = () => {
	localStorage.removeItem("access_token");
	// window.location.href = "http://localhost:3000/";
	window.location.href = "https://kathmandufresh.com.au/admin/";
};

export const getKeyByValue = (object, value) => {
	return Object.keys(object).find((key) => object[key] !== value);
};

export const convertarrayFlat = (items) => {
	return items.filter(Boolean).flat();
};

export const getMinValue = (item, fieldValue) =>
	item.reduce((pre, curr) =>
		pre[fieldValue] < curr[fieldValue] ? pre[fieldValue] : curr[fieldValue]
	);

export const splitName = (name) => {
	const values = name?.split(" ");
	const first_name = values[0];
	const last_name = values[1] ? name?.substr(name?.indexOf(" ") + 1) : "";
	return { first_name, last_name };
};

export const getUserLocation = () =>
	JSON.parse(localStorage.getItem("userLocation")) || {
		label: "",
		value: "",
		pincode: "Location",
	};

export const isObjectEmpty = (objectName) => {
	return JSON.stringify(objectName) === "{}";
};
export const filterObjectByValue = (object = [], value) => {
	return object.find((item) => item?.value === value);
};

export const getKaysFromObject = (items) => {
	return Object.keys(
		Object.keys(items).reduce((prev, key) => {
			items[key] === true && (prev[key] = items[key]);
			return prev;
		}, {})
	);
};
export default function getErrorField(errors, name) {
	const fieldName = name.split(".");
	return !isObjectEmpty(errors)
		? fieldName?.length === 2
			? errors[fieldName[0]][fieldName[1]]
			: errors[name]
		: "";
}

export const getArrayIndexByArrayObjectValue = (object, item) => {
	const selectedIndex = object.findIndex((p) => p.value === item);
	return selectedIndex > 0 ? selectedIndex : 0;
};
