import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { useForm, Controller } from "react-hook-form";

import { useDispatch } from "react-redux";

// import { ChangeOrderStatus } from "../../../../action";
import ModalBox from "../../../components/DesignSystem/ModalBox";
import { OutlineButton } from "../../../components/DesignSystem/Buttons";
import { sendNotification } from "../../../action";

const resData = {
	info: {
		id: "",
	},
};
const SendNotification = forwardRef(({}, ref) => {
	const dispatch = useDispatch();
	const openModal = useRef();
	const {
		formState: { errors },
		handleSubmit,
		control,
		reset,
		setValue,
		getValues,
	} = useForm({
		defaultValues: {
			...resData,
		},
	});

	useImperativeHandle(ref, () => ({
		handleSendNotification(item) {
			if (item) {
				//console.log({ item });
				setValue("info.id", item);
			} else {
				reset(resData);
			}
			openModal.current.handleModalBox();
		},
	}));
	const handleSendNotification = () => {
		//console.log(getValues("info.id"));
		dispatch(sendNotification({ id: getValues("info.id") }));
		openModal.current.handleModalBox(false);
	};

	return (
		<ModalBox ref={openModal} size="sm" title="Confirmation">
			<div className="container p-2">
				<div className="p-2">
					{/* <form className="box" onSubmit={handleSubmit(onSubmit)}> */}
					<div className="row">
						<div className="col-md-12 mt-3 mb-3" style={{ fontSize: "17px" }}>
							Are you want to send notification?
						</div>
					</div>

					<div className="d-flex justify-content-end mt-1 ">
						<OutlineButton
							label="Send"
							onClick={() => handleSendNotification()}
						/>
					</div>
					{/* </form> */}
				</div>
			</div>
		</ModalBox>
	);
});
export default SendNotification;
