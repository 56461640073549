import { createSlice } from "@reduxjs/toolkit";
import { getSelector } from "./helper";
import { fetchBannerDetails } from "../action";

const bannerSlice = createSlice({
	name: "bannerMng",
	initialState: {
		bannersDetails: [],
		loading: false,
		isSuccess: false,
		message: "",
	},
	reducers: {
		clear: (state) => {
			state.status = false;
			state.data = [];
			state.loading = false;
		},
	},
	extraReducers: {
		[fetchBannerDetails.pending]: (state, action) => {
			state.loading = true;
		},
		[fetchBannerDetails.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.bannersDetails = payload?.items;
		},
		[fetchBannerDetails.rejected]: (state, action) => {
			state.loading = false;
		},
	},
});

export const { clear } = bannerSlice.actions;

export const selectBannerInfo = getSelector("bannerSlice", "bannersDetails");
export default bannerSlice.reducer;
