import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import styled from "styled-components";
import { InputField } from "../FormField";
import { forwardRef } from "react";
import { useImperativeHandle } from "react";
import { UploadIcon } from "../Icons/Icons";
// import { InputField } from "../../../components/DesignSystem/FormField";
// import { BsCamera } from "react-icons/bs";
const defaultImage = require(`../../../images/drop.jpg`);

const ImageStyle = styled.div`
  .img-sec {
    width: 165px;
    max-height: 132px;
    min-height: 130px;
    border-radius: 5px;
    object-fit: cover;
  }

  .input-file {
    opacity: 0;
    position: absolute;
    z-index: 1;
    width: 165px;
    max-height: 132px;
    min-height: 130px;
    top: 25px;
  }
`;
const ImageContainer = styled.div`
  flex-direction: column;
  position: relative;
  .overlay {
    opacity: 0;
    cursor: pointer;
    position: absolute;
    z-index: 1;
    top: 100px;
    left: 4rem;
  }
  .div-containt :hover {
    .overlay {
      opacity: 1;
    }
  }
  .upload-title {
    font-size: 15px;
  }
`;
export const Image = forwardRef(
  (
    { control, errors, setValue, image, isLabel = true, image_name = "image" },
    ref
  ) => {
    const [previmage, setImage] = useState(defaultImage);
    const [imageValidationMsg, setImageValidationMsg] = useState("");
    useImperativeHandle(ref, () => ({
      resetImage(image) {
        //setImage(defaultImage);
        setImage(
          (image && `${process.env.REACT_APP_BASE_URL}/public${image}`) ||
            defaultImage
        );
      },
    }));

    const handleChange = (event, imageName) => {
      const image = event.target.files[0];
      const maximumSize = 1 * 1024 * 1024;
      //console.log(maximumSize);
      let returnFlag = true;
      if (!image) {
        setImageValidationMsg("Image is required");
        returnFlag = false;
      }
      if (image && !image.name.match(/\.(jpg|jpeg|png|PNG|JPG|JPEG)$/)) {
        setImageValidationMsg("Please select a valid image.");
        //setImage(defaultImage);
        returnFlag = false;
      }
      if (returnFlag) {
        setValue(JSON.stringify(imageName), event.target.files[0]);
        setImage(URL.createObjectURL(event.target.files[0]));
        setImageValidationMsg("");
      }

      return returnFlag;
    };
    const onInputClick = (event) => {
      event.target.value = "";
    };
    return (
      <ImageContainer>
        {" "}
        <ImageStyle>
          <span className="overlay">
            <UploadIcon size={50} />
          </span>
          <Controller
            name={image_name}
            control={control}
            render={({ field: { onChange, name, value, ref } }) => (
              <InputField
                name={name}
                value={value}
                className="input-file"
                errors={errors}
                type="file"
                onClick={onInputClick}
                //onChange={onChange}
                // onChange={(e) => {
                //   onChange(e);
                //   //setImage(URL.createObjectURL(e.target.files[0]));
                //   handleChange(e);
                // }}

                onChange={
                  (e) => {
                    handleChange(e, name);
                  }

                  // onChange({
                  // 	target: { value: e.target.files[0], name: name },
                  // })
                }
                ref={ref}
              />
            )}
          />{" "}
          <div className="div-containt">
            <img
              src={previmage || defaultImage}
              alt="..."
              className="img-sec border shadow-sm"
            ></img>{" "}
          </div>
        </ImageStyle>
        {false && (
          <span className="my-3 upload-title">Drag and Drop / Upload</span>
        )}
        <div className="text-danger">{imageValidationMsg}</div>
      </ImageContainer>
    );
  }
);
