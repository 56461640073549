import React, { useEffect } from "react";
import { columnDefs, defaultColDef } from "./Header";
import AGTable from "../../../../components/DesignSystem/AGTable";

import { Typography } from "../../../../components/DesignSystem/Typography";
import { useDispatch, useSelector } from "react-redux";
import { restoreCategory } from "../../../../action";
import { getTrashSubCategory } from "../../../../redux/trashSlice";

const SubCategory = () => {
	const dispatch = useDispatch();
	const selectSubCategorysDet = useSelector(getTrashSubCategory);
	console.log({ selectSubCategorysDet });

	const handleRestore = (id) => {
		dispatch(restoreCategory({ id: id, status: "A" }));
	};

	return (
		<>
			<div className="d-flex justify-content-between mb-2">
				<Typography align="text-start">Category Listing</Typography>
			</div>
			<AGTable
				handleRestore={handleRestore}
				rowData={selectSubCategorysDet}
				columnDefs={columnDefs}
				defaultColDef={defaultColDef}
			/>
		</>
	);
};
export default SubCategory;
