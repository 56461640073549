import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { SelectField } from "../../../components/DesignSystem/FormField";
import DatePickers from "../../../components/DesignSystem/DatePickers";
import { useDispatch } from "react-redux";
import { fetchOrderReports, fetchUserTypeDetails } from "../../../action";
import { currentYear } from "../../../util";
const optionsForYear = [
	{ value: "2020", label: "2020" },
	{ value: "2021", label: "2021" },
	{ value: "2022", label: "2022" },
	{ value: "2023", label: "2023" },
	{ value: "2024", label: "2024" },
];
const optionsForType = [
	{ value: "Y", label: "Year Wise" },
	{ value: "R", label: "Range Wise" },
];
const defaultDateRange = { start_date: new Date(), end_date: new Date() };
const SearchBar = () => {
	const [typeWise, setTypeWise] = useState("");
	const [dateRange, setSetDateRange] = useState(defaultDateRange);
	const dispatch = useDispatch();

	useEffect(() => {
		setValue("info.typeWise", { value: "Y", label: "Year Wise" });
		setValue("info.year", { value: currentYear(), label: currentYear() });
		dispatch(fetchUserTypeDetails());
		dispatch(
			fetchOrderReports({
				type: "Y",
				year: currentYear(),
			})
		);
		setTypeWise("Y");
	}, [dispatch]);

	const {
		formState: { errors },
		control,
		setValue,
	} = useForm({
		defaultValues: {},
	});

	const handleGetDataForYear = useCallback(
		(year) => {
			dispatch(
				fetchOrderReports({
					type: "Y",
					year: year,
				})
			);
		},
		[dispatch]
	);

	const handleCallDateRange = useCallback(() => {
		console.log(dateRange.end_date);
		dateRange.start_date &&
			dateRange.end_date &&
			dispatch(
				fetchOrderReports({
					type: "R",
					...dateRange,
				})
			);
	}, [dateRange, dispatch]);

	const clearAllState = useCallback(() => {
		setValue("info.year", " ");
		setSetDateRange(defaultDateRange);
	}, [setValue]);

	return (
		<div className="d-flex  gap-2">
			<Controller
				name="info.typeWise"
				control={control}
				render={({ field: { onChange, value, name, ref } }) => (
					<SelectField
						name={name}
						value={value}
						optionsItems={optionsForType}
						errors={errors}
						placeholder="Select type wise"
						errorMessage="Please select type."
						ref={ref}
						onChange={(e) => {
							setTypeWise(e?.value);
							clearAllState();
							onChange(e);
						}}
					/>
				)}
			/>
			{typeWise === "R" ? (
				<>
					<DatePickers
						name="start_date"
						value={dateRange.start_date}
						maxDate={new Date()}
						onChange={(data) => {
							setSetDateRange({ ...dateRange, start_date: data });
							handleCallDateRange();
						}}
					/>
					<DatePickers
						name="end_date"
						value={dateRange.end_date}
						maxDate={new Date()}
						onChange={(data) => {
							setSetDateRange({ ...dateRange, end_date: data });
							handleCallDateRange();
						}}
					/>
				</>
			) : (
				<Controller
					name="info.year"
					control={control}
					render={({ field: { onChange, value, name, ref } }) => (
						<SelectField
							name={name}
							value={value}
							optionsItems={optionsForYear}
							errors={errors}
							placeholder="Select year"
							errorMessage="Select year."
							ref={ref}
							onChange={(e) => {
								onChange(e);
								handleGetDataForYear(e?.value);
							}}
						/>
					)}
				/>
			)}
		</div>
	);
};
export default SearchBar;
