import React, { useEffect, useRef } from "react";
import AGTable from "../../../../components/DesignSystem/AGTable";
import { columnDefs, defaultColDef } from "./Header";
import { useDispatch, useSelector } from "react-redux";
import { selectInventorySellingProductsInfo } from "../../../../redux/inventorySlice";
import { fetchInventorySellingProductsDetails } from "../../../../action";

const SellingProducts = () => {
	const dispatch = useDispatch();
	const selectSellingProductsDet = useSelector(
		selectInventorySellingProductsInfo
	);
	useEffect(() => {
		dispatch(fetchInventorySellingProductsDetails());
	}, [dispatch]);

	return (
		<>
			<AGTable
				rowData={selectSellingProductsDet}
				columnDefs={columnDefs}
				defaultColDef={defaultColDef}
				removeAction={true}
			/>
		</>
	);
};
export default SellingProducts;
