import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import ModalBox from "../../../components/DesignSystem/ModalBox";
import styled from "styled-components";
import { CloseIcon } from "../../../components/DesignSystem/Icons/Icons";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchGenricStateDetails,
  updateStateDetails,
} from "../../../redux/genricStateSlice";
import { isObjectEmpty } from "../../../util";
import { ImageUploadFile } from "./ImageUploadFile";

const AddVariantImage = forwardRef(({}, ref) => {
  const openModal = useRef();
  const uploadFileRef = useRef();
  const dispatch = useDispatch();
  // const [photosPreviewList, setPhotosPreviewList] = useState([]);
  const [photosFilesList, setPhotosFilesList] = useState([]);

  const [photoIndex, setPhotoIndex] = useState("");
  const getImages = useSelector(fetchGenricStateDetails);

  useImperativeHandle(ref, (item) => ({
    handleGallery(item) {
      uploadFileRef.current.resetImage();
      setPhotoIndex(item);
      if (!isObjectEmpty(getImages)) {
        let imageData = [];
        Object.entries(getImages).map(([key, value]) => {
          if (key === item) {
            imageData = value;
          }
        });
        setPhotosFilesList(imageData);
      }

      openModal.current.handleModalBox();
    },
  }));

  // const removeDiv = (id, removeDiv) => {
  //   const drx = photosPreviewList?.filter((val, key) => id !== key);

  //   dispatch(
  //     updateStateDetails({
  //       [photoIndex]: drx,
  //     })
  //   );
  // };

  // const onSubmit = ({ varientImageArray }) => {
  //   const drx = varientImageArray?.filter(
  //     ({ c_image_name }) => c_image_name !== undefined
  //   );
  //   console.log({ varientImageArray }, { photosPreviewList }, { drx });

  //   if (Boolean(drx?.length)) {
  //     dispatch(
  //       updateStateDetails({
  //         [photoIndex]: [...drx, ...photosPreviewList],
  //       })
  //     );
  //   }
  //   // reset(restData);
  // };

  function uploadSingleFile(e) {
    console.log(e, "=============================");
    setPhotosFilesList([
      ...photosFilesList,
      { c_image_name: e.target.files[0] },
    ]);
    // setPhotosPreviewList([
    //   ...photosPreviewList,
    //   URL.createObjectURL(e.target.files[0]),
    // ]);
  }

  function upload() {
    console.log(photosFilesList);
    setPhotosFilesList([]);
    //if (Boolean(photosFilesList?.length)) {
    dispatch(
      updateStateDetails({
        [photoIndex]: [...photosFilesList],
      })
    );
    // }
    openModal.current.handleModalBox();
  }

  function deleteFile(e) {
    // const priviewList = photosPreviewList.filter((item, index) => index !== e);
    const fileList = photosFilesList.filter((item, index) => index !== e);
    console.log({ fileList });
    // setPhotosPreviewList(priviewList);
    setPhotosFilesList(fileList);
  }
  console.log(photosFilesList);
  return (
    <ModalBox
      ref={openModal}
      size="md"
      title="Product Image"
      isClosedButton={false}
    >
      <Container>
        <form>
          <div className="row  text-center">
            <div className="col-6 p-3">
              <ImageUploadFile
                uploadSingleFile={uploadSingleFile}
                ref={uploadFileRef}
              />
            </div>
            {Boolean(photosFilesList?.length) &&
              photosFilesList?.map(({ c_image_name }, index) => {
                return (
                  <div
                    className="image-preview-addmore col-6 p-3 d-flex"
                    key={index}
                  >
                    <ImageStyle>
                      <img src={URL.createObjectURL(c_image_name)} alt="" />
                    </ImageStyle>
                    <span
                      className="cp close-icon"
                      onClick={() => deleteFile(index)}
                    >
                      <CloseIcon size={25} />
                    </span>
                  </div>
                );
              })}
          </div>

          <div className="text-end mt-4">
            <button
              type="button"
              className="btn btn-primary btn-block"
              onClick={upload}
            >
              Save/Close
            </button>
          </div>
        </form>
      </Container>
    </ModalBox>
  );
});
export default AddVariantImage;
const Container = styled.div.attrs(() => ({
  className: "container px-4",
}))`
  margin-bottom: 2rem;
  .close-icon {
    margin-top: -10px;
  }
`;
const EmptyDiv = styled.div`
  font-size: 1.2rem;
  color: #3d3737;
  font-weight: 500;
`;
const ImageStyle = styled.div`
  position: relative;
  margin-left: 20px;
  img {
    width: 165px;
    max-height: 132px;
    min-height: 130px;
    border-radius: 5px;
    object-fit: cover;
  }
  svg {
    cursor: pointer;
    position: absolute;
    top: 60px;
    left: 66px;
  }
  .input-file {
    opacity: 0;
    position: absolute;
    z-index: 1;
    top: 65px;
    width: 25px;
    left: 66px;
  }
`;
