const NotificationCount = ({ count }) => {
  return (
    <span
      class="position-absolute translate-middle badge rounded-pill bg-primary"
      style={{ fontSize: 9 }}
    >
      {count}
    </span>
  );
};
export default NotificationCount;
