import React, { useEffect } from "react";
import { columnDefs, defaultColDef } from "./Header";
import AGTable from "../../../../components/DesignSystem/AGTable";

import { Typography } from "../../../../components/DesignSystem/Typography";
import { useDispatch, useSelector } from "react-redux";
import { restoreCategory } from "../../../../action";
import { getTrashCategory } from "../../../../redux/trashSlice";

const Category = () => {
  const dispatch = useDispatch();
  const selectCategorysDet = useSelector(getTrashCategory);

  const handleRestore = (id) => {
    dispatch(restoreCategory({ id: id, status: "A" }));
  };

  return (
    <>
      <div className="d-flex justify-content-between mb-2">
        <Typography align="text-start">Category Listing</Typography>
      </div>
      <AGTable
        handleRestore={handleRestore}
        rowData={selectCategorysDet}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
      />
    </>
  );
};
export default Category;
