import { createSlice } from "@reduxjs/toolkit";
import { getSelector } from "./helper";
import { fetchPaymentDetails } from "../action";

const paymentSlice = createSlice({
	name: "paymentMng",
	initialState: {
		paymentsDetails: [],
		loading: false,
		isSuccess: false,
		message: "",
	},
	reducers: {
		clear: (state) => {
			state.status = false;
			state.data = [];
			state.loading = false;
		},
	},
	extraReducers: {
		[fetchPaymentDetails.pending]: (state, action) => {
			state.loading = true;
		},
		[fetchPaymentDetails.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.paymentsDetails = payload?.items;
		},
		[fetchPaymentDetails.rejected]: (state, action) => {
			state.loading = false;
		},
	},
});

export const { clear } = paymentSlice.actions;

export const selectPaymentInfo = getSelector("paymentSlice", "paymentsDetails");
export default paymentSlice.reducer;
