import styled from "styled-components";

export const Container = styled.div.attrs(() => ({
  className: "",
}))`
    background: "#0008"
    color: "white";
    top: 0;
    left: 0;
    z-index: 9999;
    
  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .loading svg {
    font-size: 5px;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 1px;
    animation: text 1s ease-in-out infinite;
  }
  @keyframes text {
    50% {
      opacity: 0.1;
    }
  }
  .loading polygon {
    stroke-dasharray: 22;
    stroke-dashoffset: 1;
    animation: dash 4s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite
      alternate-reverse;
  }
  @keyframes dash {
    to {
      stroke-dashoffset: 234;
    }
  }
`;

export const ModalContainer = styled.div.attrs(() => ({ className: "card" }))`
  width: 18rem;
  height: 14.3rem;
  .button {
    color: var(--primary);
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 1px;
    border: 1px solid;
    padding: 7px 24px;
    border-radius: 13px;
  }
`;
