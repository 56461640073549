import React, { useCallback, useEffect, useMemo, useState } from "react";
import NavTabs from "../../../components/DesignSystem/NavTabs";
import { InventoryTabs } from "../../../config/nav-config";
import styled from "styled-components";

import SellingProducts from "./SellingProducts";
import { Typography } from "../../../components/DesignSystem/Typography";
import Lowstock from "./lowStock";
import OutOfStock from "./OutofStock";
import { useDispatch } from "react-redux";
import { fetchInventoryLowStockDetails } from "../../../action";
import { useLocation } from "react-router-dom";

const Container = styled.div.attrs(() => ({
  className: "",
}))`
  .ag-root-wrapper {
    border-radius: unset;
  }
`;

const Inventory = () => {
  const [inventoryType, setInventoryType] = useState("L");
  const dispatch = useDispatch();
  const location = useLocation();
  const paramData = location.state;

  const getActiveNavValue = useCallback((activeUser) => {
    setInventoryType(activeUser?.value);
  }, []);
  useEffect(() => {
    dispatch(fetchInventoryLowStockDetails());
  }, [dispatch]);
  useEffect(() => {
    console.log(paramData?.stockType);
    paramData?.stockType && setInventoryType(paramData?.stockType);
  }, [paramData?.stockType]);
  return (
    <Container>
      <div className="d-flex justify-content-between mb-2">
        <Typography align="text-start">Inventory Management</Typography>
      </div>
      <div style={{ backgroundColor: "#e9e9e9" }}>
        <NavTabs
          navItems={InventoryTabs}
          getActiveNavValue={getActiveNavValue}
          defaultItem={paramData?.stockType || 0}
        />
      </div>
      {inventoryType === "L" && <Lowstock />}
      {inventoryType === "O" && <OutOfStock />}
      {inventoryType === "S" && <SellingProducts />}
    </Container>
  );
};
export default Inventory;
