import { useSelector } from "react-redux";

import { selectUserAccountInfo } from "../redux/userSlice";

//
const useIsAuthenticate = () => {
  const { email, user_type, id } = useSelector(selectUserAccountInfo);

  const isAdminAuthenticated = user_type === "A" || "M";
  const isRestAuthenticated = user_type === "M";

  return {
    isAdminAuthenticated,
    isRestAuthenticated,
    isAuthenticate: Boolean(user_type),
    userType: user_type,
  };
};

export default useIsAuthenticate;
