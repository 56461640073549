import React, { Suspense } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
// import { Dashboard } from "../../container/Dashboard";

import useIsAuthenticate from "../../hooks/useIsAuthenticate";
import Users from "../../container/admin/users";
import Banner from "../../container/admin/banner";
import Notification from "../../container/admin/notification";
import Product from "../../container/admin/products";
import Archives from "../../container/admin/archives";
import Dashboard from "../../container/Dashboard";
import Inventory from "../../container/admin/inventory";
import Order from "../../container/admin/orders";
import AddProduct from "../../container/admin/products/AddProduct";
import EditProduct from "../../container/admin/products/EditProduct";
import ViewOrder from "../../container/admin/orders/ViewOrder";
import PaymentSetting from "../../container/admin/setting";
import CategorySubcategory from "../../container/admin/categorySubcategory";
export function SuperAuthRoute({ component, ...rest }) {
  const { isAdminAuthenticated } = useIsAuthenticate();
  return isAdminAuthenticated ? <Outlet /> : <Navigate to="/" />;
}
export const SuperAdminRouters = () => {
  return (
    <Suspense fallback={<div>Loading....</div>}>
      <Routes>
        <Route exact element={<SuperAuthRoute />}>
          <Route exact path="/" element={<Dashboard />} />
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/users" element={<Users />} />
          <Route exact path="/categories" element={<CategorySubcategory />} />
          <Route exact path="/banners" element={<Banner />} />
          <Route exact path="/settings" element={<PaymentSetting />} />
          <Route exact path="/notification" element={<Notification />} />
          <Route exact path="/products" element={<Product />} />
          <Route exact path="/create-product" element={<AddProduct />} />
          <Route exact path="/manage-product" element={<EditProduct />} />
          <Route exact path="/order" element={<Order />} />
          <Route exact path="/order-details" element={<ViewOrder />} />
          <Route exact path="/archives" element={<Archives />} />
          <Route exact path="/inventory" element={<Inventory />} />
        </Route>
        {/* <Route exact path="/cuisine-item-display" element={<Cuisine />} /> */}
      </Routes>
    </Suspense>
  );
};
