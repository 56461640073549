import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import styled from "styled-components";
import { InputField } from "../FormField";
import { forwardRef } from "react";
import { useImperativeHandle } from "react";
import { UploadIcon } from "../Icons/Icons";

const defaultImage = require(`../../../images/promo/promo.jpg`);

const ImageStyle = styled.div`
	.img-sec {
		width: 100%;
		max-height: 128px;
		min-height: 128px;
		border-radius: 10px;
		object-fit: cover;
	}

	.input-file {
		opacity: 0;
		position: absolute;
		z-index: 1;
		width: 100%;
		max-height: 132px;
		min-height: 130px;
		top: 25px;
	}
`;
const ImageContainer = styled.div`
	flex-direction: column;
	position: relative;
	.overlay {
		cursor: pointer;
		position: absolute;
		z-index: 1;
		top: 100px;
		left: 20rem;
	}

	.upload-title {
		font-size: 15px;
	}
`;
export const Banner = forwardRef(({ control, errors, image }, ref) => {
	const [previmage, setImage] = useState(defaultImage);
	const [imageValidationMsg, setImageValidationMsg] = useState("");
	useImperativeHandle(ref, () => ({
		resetImage(image) {
			//setImage(defaultImage);
			setImage(
				(image && `${process.env.REACT_APP_BASE_URL}/public${image}`) ||
					defaultImage
			);
		},
	}));

	const handleChange = (event) => {
		const image = event.target.files[0];
		if (!image) {
			setImageValidationMsg("Image is required");

			return false;
		}
		if (!image.name.match(/\.(jpg|jpeg|png|PNG|JPG|JPEG)$/)) {
			setImageValidationMsg("select valid image.");
			setImage(defaultImage);
			return false;
		}

		setImage(URL.createObjectURL(event.target.files[0]));
		setImageValidationMsg("");
	};

	return (
		<ImageContainer>
			<ImageStyle>
				<span className="overlay">
					<UploadIcon size={50} />
				</span>
				<Controller
					name="image"
					control={control}
					render={({ field: { onChange, name, value, ref } }) => (
						<InputField
							name={name}
							value={value}
							className="input-file "
							errors={errors}
							type="file"
							onChange={(e) => {
								onChange(e);
								handleChange(e);
							}}
							ref={ref}
						/>
					)}
				/>{" "}
				<label className="upload-title px-2 mb-2">Drag and Drop / Upload</label>
				<img
					src={previmage || defaultImage}
					alt="..."
					className="img-sec"
				></img>
			</ImageStyle>

			<div className="text-danger">{imageValidationMsg}</div>
		</ImageContainer>
	);
});
