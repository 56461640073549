import * as Yup from "yup";

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

export const ProductShema = Yup.object().shape({
	info: Yup.object().shape({
		product_name: Yup.string().required(""),
		quantity_alert: Yup.number().required(""),
		// product_description: Yup.string().required(""),
		product_category: Yup.object().shape({
			value: Yup.string().required("Required"),
			label: Yup.string().required("Required"),
		}),
		category: Yup.object().shape({
			value: Yup.string().required("Required"),
			label: Yup.string().required("Required"),
		}),

		varientArray: Yup.array(
			Yup.object({
				unit: Yup.number()
					.positive("Must be a positive value")
					.min(0, "must be greater than or equal to 1!")
					.required("Required"),
				price: Yup.number()
					.positive("Must be a positive value")
					.min(0, "must be greater than or equal to 0!")
					.required("Required"),
				discount: Yup.number()
					.positive("Must be a positive value")
					.min(0, "must be greater than or equal to 0!")
					.required("Required"),
				selling_price: Yup.number()
					.positive("Must be a positive value")
					.min(0, "must be greater than or equal to 0!")
					.required("Required"),
				stock: Yup.number()
					.positive("Must be a positive value")
					.min(0, "must be greater than or equal to 0!")
					.required("Required"),
				description: Yup.string().required("Required"),
			})
		).required("Required"),
	}),
});
