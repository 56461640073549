import { createSlice } from "@reduxjs/toolkit";
import { getSelector } from "./helper";
import { fetchNotificationDetails } from "../action";
import { notificationNormalize } from "../Normalization/NormalizerSetUp";

const notificationSlice = createSlice({
	name: "notificationMng",
	initialState: {
		notificationDetails: [],
		loading: false,
		isSuccess: false,
		message: "",
	},
	reducers: {
		clear: (state) => {
			state.status = false;
			state.data = [];
			state.loading = false;
		},
	},
	extraReducers: {
		[fetchNotificationDetails.pending]: (state, action) => {
			state.loading = true;
		},
		[fetchNotificationDetails.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.notificationDetails = notificationNormalize(payload?.items);
		},
		[fetchNotificationDetails.rejected]: (state, action) => {
			state.loading = false;
		},
	},
});

export const { clear } = notificationSlice.actions;

export const selectNotificationInfo = getSelector(
	"notificationSlice",
	"notificationDetails"
);
export default notificationSlice.reducer;
