import axios from "axios";
import { appurl } from "./index";

const parseJSON = (response) => {
	return { ...response?.data, headers: response?.headers };
};

const parseError = (error) => {
	const parseError = {
		...error?.response?.data,
		httpStatus: error?.response?.status,
	};
	throw parseError;
};

const payloadApi = (request) => {
	const endpointUrl = appurl(request.endpoint);
	switch (request.method) {
		case "get":
			return {
				Accept: "application/json",
				method: "GET",
				url: request?.filter
					? `${endpointUrl}?${request?.filter}`
					: endpointUrl,
				data: request.queryparam,
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${localStorage.getItem("access_token")}`,
				},
			};
		case "getById":
			return {
				Accept: "application/json",
				method: "GET",
				url: `${endpointUrl}/${request?.id}`,
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${localStorage.getItem("access_token")}`,
				},
			};
		case "post":
			return {
				Accept: "application/json",
				method: "POST",
				url: endpointUrl,
				data: request.object,
				headers: {
					"Content-Type": "multipart/form-data",
					// "Content-Type": "application/pdf",
					Authorization: `Bearer ${localStorage.getItem("access_token")}`,
				},
			};
		default:
			return "state";
	}
};
export const request = (parametar) => {
	return axios(payloadApi(parametar)).then(parseJSON).catch(parseError);
};
