import React, { useMemo, useState } from "react";
const getDate = (value) => {
	var dateParts = value.split("/");
	return new Date(
		Number(dateParts[2]),
		Number(dateParts[1]) - 1,
		Number(dateParts[0])
	);
};
// {params.value}

const dateFilterParams = {
	filters: [
		{
			filter: "agDateColumnFilter",
			filterParams: {
				comparator: (filterDate, cellValue) => {
					if (cellValue == null) return -1;
					return getDate(cellValue).getTime() - filterDate.getTime();
				},
			},
		},
		{
			filter: "agSetColumnFilter",
			filterParams: {
				comparator: (a, b) => {
					return getDate(a).getTime() - getDate(b).getTime();
				},
			},
		},
	],
};
export const columnDefs = [
	{
		headerName: "Order No",
		field: "order_no",
		filter: "agMultiColumnFilter",
	},
	{
		field: "Customer",
		cellRenderer: (params) => {
			return <>{params?.data?.customer?.full_name}</>;
		},
	},
	{
		headerName: "Quantity",
		field: "item_quantity",
		filter: "agMultiColumnFilter",
	},
	{
		headerName: "Total Price",
		field: "total_amount",
		filter: "agMultiColumnFilter",
	},
	// {
	// 	headerName: "Payment Method",
	// 	field: "payment_method",
	// 	filter: "agMultiColumnFilter",
	// },
	// {
	// 	headerName: "Payment Status",
	// 	field: "payment_status",
	// 	filter: "agMultiColumnFilter",
	// },
	{
		headerName: "Order Status",
		field: "order_status",
		//cellClicked: handleStatusChange,
		filter: "agMultiColumnFilter",
	},
	{
		headerName: "Order Date",
		field: "created_at",
		filter: "agMultiColumnFilter",
	},
];
export const defaultColDef = {
	flex: 1,
	minWidth: 100,
	menuTabs: ["filterMenuTab"],
};
